const ToursList = [
  {
    id: "my-son-sanctuary-luxury-tour",
    name: "My Son Sanctuary Luxury Tour",
    price: [
      {
        price: 850000,
        unit: "Person",
        currency: "VND",
        description: "Adults, Children 10 years and older",
      },
      {
        price: 650000,
        unit: "Child",
        currency: "VND",
        description: "Children 4 to 9 years",
      },
      {
        price: 0,
        unit: "Child",
        currency: "VND",
        description: "Children younger than 4 years",
      },
    ],
    maxGroupSize: 12,
    tourSchedules: { start: "8 AM", end: "1:30 PM", scheduleName: "" },
    tourSummary: [
      "By following the trail of history, finding the vestiges of the ancients and reliving the memories of a civilization, we would understand more the value of life.",
      "We look forward to bringing you the most enjoyable experiences in your journeys by discovering the biggest center of Hindu temples in the central of Viet Nam named My Son, which was built around the 4th century. This would be a great chance to learn about the cultural diversity of Vietnam through 4000 years.",
      "With the combination of cultural tourism, culinary and sightseeing, we expect you to remember Viet Nam as a friendly, beautiful and diverse culinary culture country. Come and join us!",
    ],
    tourItinerary: [
      "Our guide pick you up at your hotel and depart to My Son Sanctuary – The World Cultural Heritage.",
      "Arrive at My Son and we’ll have 2 hours to discover the site by walking throughout the landscape, learning histories with our local English speaking guide, enjoying the Champa’s traditional performances.",
      "Get back to mini van and move to a local house to learn how to make ricepaper, you will enjoy your own hand-making product later on!",
      "Back to the van and have lunch at another local house, relax with cold herbal tea, nice foods and fresh air.",
      "Take a boat trip to Hoi An with nice and cool breeze on boarding, beautiful river scenery on the biggest river in the Central of vietnam named as Thu Bon River.",
      "Arrive at Hoi An, we will drop you at your hotel. Tour ends!",
    ],
    tourIncludes: [
      "A two ways hotel transfers pick up and drop off (100km round trip distance)",
      "The 150,000 VND entrance fees and 30,000 VND of electric car transfer",
      "Lunch will be served with Vietnamese Traditional foods",
      "Boat trip on Thu Bon River",
      "English speaking guide",
      "A bottle of water",
    ],
    tourExcludes: [
      "Personal expense services not mentioned above",
      "Drinks",
      "Tips and gratuities for guides and drivers",
    ],
    tourNotes: [
      "This tour is sold and operated by an independent tour company.",
      "This tour is not available during Tet holiday period",
    ],
    tourImages: ["/assets/tours/my-son-1.jpg"],
  },
  /*{
    id: "my-son-sunset-tour",
    name: "Amazing My Son Sunset Tour",
    price: [
      {
        price: 500000,
        unit: "Person",
        currency: "VND",
        description: "Adults, Children 10 years and older",
      },
      {
        price: 400000,
        unit: "Child",
        currency: "VND",
        description: "Children 4 to 9 years",
      },
      {
        price: 0,
        unit: "Child",
        currency: "VND",
        description: "Children younger than 4 years",
      },
    ],
    minGroupSize: null,
    maxGroupSize: 14,
    tourSchedules: { start: "1:30 PM", end: "6:30 PM", scheduleName: "" },
    tourSummary: null,
    tourPolicy: null,
    tourIncludes: [
      "Transfer to and from hotel (100km round trip)",
      "A Boat Trip on the Thu Bon River",
      "English speaking tour guide",
      "A bottle of water",
    ],
    tourExcludes: [
      "Entrance Fee of 150,000 VND",
      "Personal expenses not mentioned above",
      "Drinks",
      "Tips & gratuities for guides and drivers",
    ],
    tourItinerary: [
      "1:30 PM - 2:00 PM: Our tour guide will pick you up at the hotel and deport to My Son Sanctuary - The World Cultural Heritage",
      "3:00 PM: Arrive at My Son and you'll have 2 hours to discover the site by walking throughout the landscape. Our English speaking tour guide will go over the history of My Son and you'll have the opportunity to enjoy the Champa's traditional performance",
      "5:15 PM: Depart from My Son and transfer to the wharf where you will take a boat trip to Hoi An. While on the boat you'll enjoy the beautiful sunset on the Thu Bon River, which is biggest river in the central area of Vietnam. This will be time to get your camera ready and capture some of the local people going about their day along the river",
      "6:30 PM: Arrive back in Hoi An, you'll be dropped back off at your Hotel. Tour Ends!",
    ],
    tourNotes: [
      "This tour is sold and operated by an independent tour company.",
      "The Tour will not be offered during Tet holiday period",
    ],
    tourImages: ["/assets/tours/my-son-sunset-1.jpg"],
  },
  {
    id: "ba-na-hills-golden-bridge-day-tour",
    name: "Ba Na Hills - Golden Bridge Day Tour",
    price: [
      {
        price: 1375000,
        unit: "Person",
        currency: "VND",
        description: "Adults (height greater than 1.4 meters",
      },
      {
        price: 1195000,
        unit: "Child",
        currency: "VND",
        description:
          "Children (height greater than 1 meter, but less than 1.4 meters",
      },
      {
        price: 0,
        unit: "Child",
        currency: "VND",
        description: "Children less than 1 meter in height",
      },
    ],
    minGroupSize: null,
    maxGroupSize: 12,
    tourSchedules: { start: "7 AM", end: "6:45 PM", scheduleName: "" },
    tourSummary: null,
    tourPolicy: [
      "There is a 20% cancellation fee for canceling 12 hours before the start of the tour",
      "There is a 35% cancellation fee for canceling on the day of the tour",
    ],
    tourIncludes: [
      "Round trip transportation (120km round-trip)",
      "Ba Na Hill Entrance and cable car fees",
      "Buffet lunch",
      "English speaking tour guide",
      "a bottle of water",
    ],
    tourExcludes: [
      "The 100,000 VND Wax Museum entrance fee",
      "Personal expenses and services not mentioned above",
      "Tips and gratuities for guide and driver",
    ],
    tourItinerary: [
      "7:30 AM - 8:00: Our Minivan and our guide pick you up at hotel lobby",
      "9:30 AM: Arrive at Ba Na Hills via Cable Cars",
      "After finishing the first cable car, you will visit the Golden Bridge. The Golden Bridge is the world's most prominent pedestrian bridges as highlighted by the British Guard newspaper. After the golden bridge you will have the opportunity to visit the French wine cellar, Le Jardin D'amourn, Linh Ung Pagoda",
      "Continue your journey by taking the second cable car to visit the French Village, Campanile, Nine Floor Goddess Shrine, Tombstone Temple, Linh Phong Monastery, Linh Chua Linh Tu Temple, and Tru Vu Tea Shop.",
      "Ride the Alpine coaster",
      "Around 12:00 PM: Buffet lunch will be provided",
      "After lunch, you can continue to explore Ba Na Hill's many attractions",
      "3:00 PM: Return to cables car and leave Ba Na Hills",
      "4:45 PM - 5:45 PM: Our minivan brings you back to your hotel and the tour ends",
    ],
    tourNotes: [
      "This tour is sold and operated by an independent tour company.",
      "The Tour will not be offered during Tet holiday period",
    ],
    tourImages: ["/assets/tours/ba-na-bridge-sunset.jpg"],
  },*/
  {
    id: "cooking-class",
    name: "Cooking Class at Moon River Restaurant",
    price: [
      { price: 750000, currency: "VND", unit: "Person", description: "" },
    ],
    minGroupSize: 2,
    maxGroupSize: null,
    tourSchedules: [
      { start: "9:30 AM", end: "1:00 PM", scheduleName: "Morning" },
      { start: "3:30 PM", end: "7:00 PM", scheduleName: "Afternoon" },
    ],
    tourSummary:
      "Understanding food is a great way to experience the fascinating culture of Vietnam.",
    tourPolicy: null,
    tourIncludes: null,
    tourExcludes: null,
    tourItinerary: [
      "Starts with a visit to the local market in the center of the city",
      "With our Chef as your guide you will select ingredients to use during the cooking class",
      "Once you've completed your trip to the local market, you will come back to the hotel and cooking class will begin",
    ],
    tourNotes: "Please schedule one day in advance",
    tourImages: ["/assets/restaurant/cooking-2.jpg"],
  },
  /*{
    id: "experience-eco-fishing-village-tour",
    name: "Experience Eco Fishing Village Tour",
    price: [
      { price: 700000, currency: "VND", unit: "Person", description: "Adults" },
      {
        price: 350000,
        currency: "VND",
        unit: "Child",
        description: "children 5 to 11 years old",
      },
      {
        price: 0,
        unit: "Unit",
        currency: "VND",
        description: "Children under 5 years",
      },
    ],
    minGroupSize: 2,
    maxGroupSize: null,
    tourSchedules: [
      { start: "8:30 AM", end: "1:00 PM", scheduleName: "Morning" },
      { start: "2:00 PM", end: "6:30 PM", scheduleName: "Afternoon" },
    ],
    tourSummary:
      " Come to join this special Tour you will enjoy a wide range of experiences – from learning traditional fishing techniques to paddling the strange and unique Vietnamese bamboo basket boats and normal fishing boats of the local fishermen while exploring the Ecological water palm system where was the safe and important revolutionary base of V.C soldiers in the past war. On this tour we provide an authentic social and cultural insight into the local Vietnamese way of life. As well you will take part in some hands-on activities that are fun, safe and interesting to people all of ages.",
    tourPolicy: null,
    tourIncludes: [
      "Afternoon tours include a Hoi An traditional dinner",
      "Morning tours include a Hoi An traditional lunch",
      "Cold Water",
      "English speaking tour guide",
      "Boat and Bicycle Transportation",
      "Roundtrip transportation",
    ],
    tourExcludes: ["Anything not mentioned in the inclusions section"],
    tourItinerary: [
      "Pick up at hotel by bicycle then cycle  to a local fishing village named Cam Thanh",
      "Riding pass narrow roads through a small local market, stop at rice paddy field, taking many pictures of water buffalos, farmers on daily working at their fields, fish and shrimp-ponds",
      "Learning how hard working of local farmers by ploughing the field, planting baby rice, riding water buffalo",
      "Learn about daily life of Vietnamese fishermen, their families and their community",
      "Joining with local fishermen on Cua Dai river and learn how to use the round net and cast net fishing",
      "Learning to paddle unique Vietnamese Bamboo Basket boats",
      "Explore the Ecological water palm system, get to know more about the true stories that happened here during war time and  hear of the extreme conditions endured by soldiers of both sides, hard work fishermen on this river for a long time.",
      "Back to a local family (Mr Cu’s home) for a local lunch, learn how to make traditional rice milk and cook traditional Hoi An pancake (Banh Xeo)",
      "Tour ends by noon then return back to hotel.",
    ],
    tourNotes: [
      "This tour is sold and operated by an independent tour company.",
      "The Tour will not be offered during Tet holiday period",
    ],
    tourImages: ["/assets/tours/eco-fishing-1.jpg"],
  },
  {
    id: "cham-island-diving-snorkeling-tour",
    name: "Cham Island Diving & Snorkeling Tour",
    price: [
      {
        price: 1200000,
        currency: "VND",
        unit: "Person",
        description: "Snorkeling and island excursion",
      },
      {
        price: 1800000,
        currency: "VND",
        unit: "Person",
        description: "1 beginner dive for non-certified divers",
      },
      {
        price: 2300000,
        currency: "VND",
        unit: "Person",
        description: "2 beginner dives for non-certified divers",
      },
      {
        price: 2100000,
        currency: "VND",
        unit: "Person",
        description: "2 dives for certified divers",
      },
    ],
    minGroupSize: 2,
    maxGroupSize: null,
    tourSchedules: [{ start: "8:00 AM", end: "5:00 PM", scheduleName: "" }],
    tourSummary: null,
    tourPolicy: null,
    tourIncludes: ["All equipment for diving or snorkeling", "Lunch"],
    tourExcludes: ["Anything not mention in the inclusions"],
    tourItinerary: [
      "8:00 AM - 8:30 AM: Leave the hotel by minivan",
      "8:45 AM: Boat departs from Cua Dai pier for Cham Island",
      "10:00 AM: Snorkeling 1 / Dive 1",
      "12:00 PM: Snorkeling 2 / Dive 2",
      "1:30 PM: Lunch on Bai Chong tropical beach",
      "2:00 PM- 3:30 PM: Time for exploring the beach or resting in the provided hammocks",
      "3:45 PM: Board the boat to leave Cham Island",
      "5:00 PM: Arrive back at your hotel in Hoi An, ",
    ],
    tourNotes: [
      "This tour is sold and operated by an independent tour company.",
      "The Tour will not be offered during Tet holiday period",
    ],
    tourImages: ["/assets/tours/cham-island-1.jpg"],
  },
  {
    id: "basket-boat-fishing-and-cooking-experience",
    name: "Basket Boat Fishing And Cooking Experience",
    price: [
      {
        price: 650000,
        currency: "VND",
        unit: "Person",
        description: "Adults",
      },
      {
        price: 325000,
        currency: "VND",
        unit: "Child",
        description: "children 5 to 11 years old",
      },
      {
        price: 0,
        currency: "VND",
        unit: "Child",
        description: "children under 5 years old",
      },
    ],
    minGroupSize: 2,
    maxGroupSize: null,
    tourSchedules: [
      { start: "8:30 AM", end: "1:00 PM", scheduleName: "Morning" },
      { start: "2:00 PM", end: "6:30 PM", scheduleName: "Afternoon" },
    ],
    tourSummary: null,
    tourPolicy: null,
    tourIncludes: [
      "Hoi An traditional lunch (morning tours) /dinner (afternoon tours)",
      "Cold water",
      "English speaking tour guide",
      "Bicycle transportation",
      "Entrance fees",
      "Roundtrip transportation",
    ],
    tourExcludes: ["Anything not mention in the inclusions"],
    tourItinerary: [
      "Tour guide pick up you at the hotel lobby, then get to Thu Bon wharf on Bach Dang Street near Hoi An central market.",
      "Joining a boat cruise along the major branch of the lower section of Thu Bon river, enjoy the beautiful scenery along both banks of this river where you watch many boats bobbing on the waters, local fishermen are on their daily work of catching fishes, mussel,. After that, we will cross Cua Dai estuary where the river meet the sea, going up to the Cua Dai river and heading for Bay Mau nipa (water coconut palm forest) grove where you can discover the daily life of the local fisherman there.",
      "Catch a Bamboo basket boat trip into water coconut palm forest with local fisherman",
      "Explore the water palm coconut paradise. Learning and sharing fishing experience with local fishermen by catching fish and purple crabs with unique fishing nets, make souvenirs with palm leaves.",
      "Get to know more about Vietnamese–American war, interesting & true stories happened during the war time.",
      "Having lunch or at the local home (Mr Cu’s home), learn how to make fresh rice milk and cook traditional Hoi An pancake (Banh Xeo).",
      "Tour ends by noon then return back to hotel.",
    ],
    tourNotes: [
      "This tour is sold and operated by an independent tour company.",
      "The Tour will not be offered during Tet holiday period",
    ],
    tourImages: ["/assets/tours/basket-boat-1.jpg"],
  },
  {
    id: "marble-mountains-am-phu-cave-monkey-mountains",
    name: "Marble Mountains - Am Phu Cave - Monkey Mountains",
    price: [
      {
        price: 550000,
        currency: "VND",
        unit: "Person",
        description: "Adults",
      },
      {
        price: 375000,
        currency: "VND",
        unit: "Child",
        description: "children 4 to 9 years old",
      },
      {
        price: 0,
        currency: "VND",
        unit: "Child",
        description: "children under 4 years old",
      },
    ],
    minGroupSize: 2,
    maxGroupSize: null,
    tourSchedules: { start: "7:30 AM", end: "1:00 PM", scheduleName: "" },
    tourSummary: null,
    tourPolicy: null,
    tourIncludes: [
      "Roundtrip transportation (90 km total distance)",
      "Entrance fees for both Marble Mountains and Am Phu Cave",
      "English speaking tour guide",
      "Lunch with local cuisine",
      "A bottle of water",
    ],
    tourExcludes: [
      "Personal expenses for services not mentioned in the inclusions (such as the fee to use the elevator to the top of Marble Mountain)",
      "Tips for guide and driver",
    ],
    tourItinerary: [
      "7:30 AM - 8:00 AM: Depart hotel",
      "8:30 AM: Arrive in Marble Mountains and stop for a walk along the site to see the local factories of stone sculptures – the most impressive handicrafts village in Da Nang",
      "9:00 AM - 10:00 AM: check in the Marble Mountain by climbing up to the top (elevator is optional). Here we will see beautiful pagodas which were built from 19th century by the last dynasty of Vietnam monarchy, visit Huyen Khong and Tang Chon Caves",
      "10:30 AM - 11:00 AM: Keep going to see the biggest fascinated cave named as Am Phu. You will learn interesting philosophies of Buddhism and take photos with amazing natural creation",
      "11:00 AM: Have lunch at the local restaurant with local and authentic Vietnamese cuisines.",
      "11:45 AM: Our van will transfer you to the next stop – Monkey Mountain. A giant statue Lady Buddha with beautiful background such as mountains and huge garden of bonsai would draw your attention. You will definitely get lost in the garden of heaven with gripping stories when gently walking and enjoy a fresh breeze.",
      "1:00 PM: the tour will end and we will arrive back at your hotel.",
    ],
    tourNotes: [
      "This tour is sold and operated by an independent tour company.",
      "The Tour will not be offered during Tet holiday period",
    ],
    tourImages: ["/assets/tours/marble-moutain-1.jpg"],
  },*/
  // {
  //   id: "hue-city-tour",
  //   name: "Hue City Tour",
  //   price: [
  //     {
  //       price: 1050000,
  //       currency: "VND",
  //       unit: "Person",
  //       description: "Adults",
  //     },
  //     {
  //       price: 787500,
  //       currency: "VND",
  //       unit: "Child",
  //       description: "children 6 to 11 years old",
  //     },
  //     {
  //       price: 0,
  //       currency: "VND",
  //       unit: "Child",
  //       description: "children under 5 years old",
  //     },
  //   ],
  //   minGroupSize: 2,
  //   maxGroupSize: null,
  //   tourSchedules: { start: "7:00 AM", end: "7:15 PM", description: "" },
  //   tourSummary: null,
  //   tourPolicy: null,
  //   tourIncludes: [
  //     "Roundtrip transportation",
  //     "Dragon boat cruise",
  //     "Experienced tour guide",
  //     "Lunch",
  //     "All entrance fees",
  //     "2 bottles of drinking water",
  //   ],
  //   tourExcludes: ["Anything not mentioned in the inclusions"],
  //   tourItinerary: [
  //     "7:00 AM - 7:30 AM: Our tour guide will pick you up from your hotel and you'll begin your journey to Hue",
  //     "Enjoy one of the best coastal roads in the world Hai Van Pass",
  //     "30 minute stop at the peak and 5 minutes for photos at Lang Co Beach",
  //     "11:00 AM: Visit the Khai Dinh Tomb",
  //     "12:30 PM: Enjoy a delicious lunch at a Local Specialty Restaurant serving Hue style dishes.",
  //     "1:30 PM: Continue to Hue’s main attraction, the Imperial Citadel.",
  //     "3:00 PM: Explore Thien Mu Pagoda, Hue’s largest pagoda.",
  //     "4:00 PM: Cruise along the Perfume River by Dragon boat.",
  //     "4:30 PM: Begin your return trip to Hoi An",
  //     "7:15 PM: Arrive back at your hotel",
  //   ],
  //   tourNotes: null,
  //   tourImages: ["/assets/tours/hue-1.jpg"],
  // },
  /*{
    id: "farming-fishinh-life-experience",
    name: "Farming & Fishing Life Experience",
    price: [
      { price: 700000, currency: "VND", unit: "Person", description: "Adults" },
      {
        price: 350000,
        currency: "VND",
        unit: "Child",
        description: "children 5 to 11 years old",
      },
      {
        price: 0,
        unit: "Unit",
        currency: "VND",
        description: "Children under 5 years",
      },
    ],
    minGroupSize: 2,
    maxGroupSize: null,
    tourSchedules: [
      { start: "8:30 AM", end: "1:00 PM", scheduleName: "Morning" },
      { start: "2:00 PM", end: "6:30 PM", scheduleName: "Afternoon" },
    ],
    tourSummary:
      "Cam Thanh village is about 5km from the old town. During the time of American-Vietnam war this was the shelter for local people and soldiers and nowadays this place is a famous destination for tourist. It is providing the most seafood products, fresh vegetable and also rice for this area and the whole Hoi An. Joining this tour you will have a good chance to meet lots of local villagers, learn some traditional fishing techniques like how to paddle the unique Vietnamese bamboo basket boats, exploring the now tranquil coconut-palm flanked waterways of a past war. During most the time of this tour, we provide an authentic social and cultural insight into the Vietnamese local way of life. As well you will take part in some hands-on activities that are fun,safe and interesting to people of all ages.",
    tourPolicy: null,
    tourIncludes: [
      "Afternoon tours include a Hoi An traditional dinner",
      "Morning tours include a Hoi An traditional lunch",
      "Cold Water",
      "English speaking tour guide",
      "Boat and Bicycle Transportation",
      "Roundtrip transportation",
      "Entrance fees",
    ],
    tourExcludes: ["Anything not mentioned in the inclusions"],
    tourItinerary: [
      "Meet your expert Guide at hotel then cycle on beautiful narrow road to local fishing village name Cam Thanh, stop at rice paddy field, fishes and shrimps farms...",
      "Visit an ancient cemetery, holy temples.",
      "Joining in a special farming work with the local farmer by ploughing the field, prepare the field then plant baby rice on the field where you just plough. You will have opportunity to ride on water buffalos and then feeding them, it is very interesting.",
      "Watching local people on  the work of making palm-thatched houses with water palm products and bamboo.",
      "Explore the water palm coconut paradise. Learning and sharing fishing experience with local fishermen by catching fish and purple crabs with unique fishing nets,  paddle the traditional Bamboo Basket boat. Make souvenirs with palm leaves.",
      "Get to know more about Vietnamese – American war, interesting & true stories  happened during the war time.",
      "Having Lunch at the local home ( Mr Cu’s home), learn how to make fresh rice milk and cook traditional Hoi An pancake (Banh Xeo).",
      "Tour ends, tour guide drops you off back to hotel.",
    ],
    tourNotes: [
      "This tour is sold and operated by an independent tour company.",
      "The Tour will not be offered during Tet holiday period",
    ],
    tourImages: ["/assets/tours/farming-fishing-1.jpg"],
  },
  {
    id: "traditional-countryside-bicycle-tour",
    name: "Traditional Countryside Bicycle Tour",
    price: [
      { price: 650000, currency: "VND", unit: "Person", description: "Adults" },
      {
        price: 430000,
        currency: "VND",
        unit: "Child",
        description: "children 6 to 12 years old",
      },
      {
        price: 90000,
        currency: "VND",
        unit: "Child",
        description: "children 4 to 5 years old",
      },
      {
        price: 0,
        unit: "Unit",
        currency: "VND",
        description: "Children under 4 years",
      },
    ],
    minGroupSize: 2,
    maxGroupSize: 10,
    tourSchedules: { start: "8:30 AM", end: "3:00 PM", scheduleName: "" },
    tourSummary: [
      "Spend a day leisurely cycling though the scenic countryside of Vietnam, far from the traffic the motor scooters, and the noises the city. You will cross the islands on the Song Thu river delta using local transportation, including ferries and floating bridge.",
      "This tour will take you to discover how wooden river boats are made, learn the secret of weaving the colorful straw Hoi An sleeping mats Try the renowned Vietnamese basket boats. You will spend the day with first-hand contact with one of the rural inhabitant of the region, far from the usual tourist circuits.",
      "At lunchtime you will be invited into the home of a local Vietnamese family for a traditional Vietnamese meal, and learn their customs and traditions. After you have relaxed for a while under the shade of a mango tree, you will Begin your return towards Hoi An, which will take about an hour.",
    ],
    tourPolicy: null,
    tourIncludes: [
      "Bicycles helmets (upon request)",
      "Bicycles with child or baby seat (upon request)",
      "Traditional home cooked meal from a local Vietnamese family (choice of meat, fish or vegetarian)",
      "French or English speaking tour guide (please indicate which you would prefer when booking)",
      "A assistant guide for tour groups larger than 3 people",
    ],
    tourExcludes: ["Hats or Caps", "Sunscreen", "Sunglasses"],
    tourItinerary: null,
    tourNotes: [
      "Children 6 years and older will be provided a children's bike",
      "Children younger than 6 and older than 2 will be passengers",
      "Tandem bicycles available upon request",
      "Please bring the following: Sunscreen, Hat, Sun glasses and if November, December or January a Windbreaker",
      "This tour is 9 km with several stops, the path that the guide will follow is flat and meant for riders of all abilities and ages.",
    ],
    tourImages: ["/assets/tours/bicycle-tour-1.jpg"],
  },*/
  //------//
  {
    id: "bana-hills-full-day-tour",
    name: "Bana Hills & Golden Bridge Tour",
    price: [
      {
        price: 1720000,
        unit: "Person",
        currency: "VND",
        description: "Adults",
      },
      {
        price: 1346000,
        unit: "Child",
        currency: "VND",
        description: "Children 1m – 1m4 high",
      },
      {
        price: 0,
        unit: "Child",
        currency: "VND",
        description: "Children under 1m",
      },
    ],
    tourSchedules: [
      {
        scheduleName: "",
        start: "8:30 AM",
        end: "5:30 PM",
      },
    ],
    tourSummary: [
      "Experience the beauty of Ba Na Hills, one of the most stunning mountains in Da Nang, located at the top of Nui Chua at 1487m altitude. Enjoy the cool and pleasant weather year-round.",
      "Admire the heights with the most modern cable car system in Southeast Asia, visit the Golden Bridge, French wine cellar, flower garden, and Linh Ung Pagoda. Take in the panoramic views of Da Nang city.",
      "Explore the French village and the peak of Chua Mountain, play games in Fantasy Park, one of the largest indoor amusement parks in Vietnam, with a variety of exciting games.",
      "Relax with a buffet lunch at a restaurant and enjoy a seamless journey with our professional guide.",
    ],
    tourItinerary: [
      "8:00 AM: Our guide will pick you up at Laluna Hoi An Riverside Hotel and start the drive to Ba Na Hills.",
      "9:45 AM: Enjoy the ride on the most modern cable car system in Southeast Asia.",
      "Visit the Golden Bridge, French wine cellar, flower garden, and Linh Ung Pagoda. Enjoy panoramic views of Da Nang city.",
      "12:00 PM: Have a buffet lunch at the restaurant.",
      "1:00 PM: After lunch, explore the French village, the peak of Chua Mountain, and play games in Fantasy Park, including Free Fall Tower, 4-5D Film, Skiver, The Death Race, and Dinosaur Park.",
      "3:30 PM: Return to the cable car for departure from Ba Na Hills.",
      "5:30 PM: Our car will take you back to Laluna Hoi An Riverside Hotel. Tour ends.",
    ],
    tourIncludes: [
      "Golden Bridge visit",
      "Transportation",
      "English-speaking guide",
      "Buffet lunch",
      "Water",
    ],
    tourExcludes: [
      "Wax statue museum (100,000 VND)",
      "Tips for guide",
      "Drinks",
    ],
    tourNotes: [],
    tourImages: [
      "/assets/tours/group/1_bana_hills/1.jpg",
      "/assets/tours/group/1_bana_hills/2.jpg",
      "/assets/tours/group/1_bana_hills/3.jpg",
      "/assets/tours/group/1_bana_hills/4.jpeg",
    ],
  },
  {
    id: "my-son-sunset-tour-with-boat-trip",
    name: "My Son Sunset Tour with Boat Trip",
    price: [
      {
        price: 500000,
        unit: "Person",
        currency: "VND",
        description: "Adults and children 10 years and older",
      },
      {
        price: 375000,
        unit: "Child",
        currency: "VND",
        description: "Children 5 to 9 years",
      },
      {
        price: 0,
        unit: "Child",
        currency: "VND",
        description: "Children under 4 years",
      },
    ],
    tourSchedules: [
      {
        scheduleName: "",
        start: "1:00 PM",
        end: "6:00 PM",
      },
    ],
    tourSummary: [
      "Experience the historic and cultural ruins of the 13th century at My Son Holy Land, once the glorious center of the Champa civilization.",
      "Walk around with our guide to see the remains of special brick towers, learn about the origin and historical significance of the site, and enjoy a traditional Cham dance performance.",
      "Enjoy a boat trip back to Hoi An with fresh air and beautiful river views.",
    ],
    tourItinerary: [
      "1:00 PM: Our guide and driver will pick you up at Laluna Hoi An Riverside Hotel lobby and depart for My Son Holy Land.",
      "2:15 PM: Arrive at My Son and walk around with our guide to see the historic and cultural ruins of the 13th century.",
      "Learn about the Champa civilization and the significance of the brick towers.",
      "Enjoy a traditional Cham dance performance with local costumes and traditional instrumental accompaniment.",
      "5:00 PM: Leave My Son by minibus to the riverside and take a private boat to Hoi An, enjoying the fresh air and river view on the wooden boat.",
      "Tour ends with drop-off at Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "Pick up and drop off",
      "English-speaking guide",
      "Boat trip",
      "Snack",
    ],
    tourExcludes: [
      "Ticket in My Son (150,000 VND)",
      "Tips for guide",
      "Drinks",
    ],
    tourNotes: [],
    tourImages: ["/assets/tours/group/2_my_son_sunset/2.jpg"],
  },
  {
    id: "hue-city-full-day-group-tour",
    name: "Hue City Full Day ",
    price: [
      {
        price: 1290000,
        unit: "Person",
        currency: "VND",
        description: "Adult",
      },
      {
        price: 967500,
        unit: "Child",
        currency: "VND",
        description: "Children 5 to 9 years old",
      },
      {
        price: 0,
        unit: "Child",
        currency: "VND",
        description: "Children under 4 years old",
      },
    ],
    tourSchedules: [
      {
        start: "7:00 AM",
        end: "7:00 PM",
      },
    ],
    tourSummary: [
      "Explore the historic city of Hue, one of the most popular tourist destinations in Vietnam. Visit the royal tombs, Imperial Citadel, and Thien Mu Pagoda with a professional local guide.",
    ],
    tourItinerary: [
      "7:00 AM: A local English-speaking guide will pick you up at the hotel lobby and depart for Hue.",
      "Drive across the spectacular Hai Van pass and the scenic fishing village of Lang Co.",
      "Visit the royal tomb of Khai Dinh, unique among the royal tombs in Hue with its monumental style.",
      "Enjoy lunch at a local restaurant.",
      "Visit the Imperial Citadel.",
      "Explore the beautiful Thien Mu Pagoda.",
      "7:00 PM: Return to the hotel and end of the tour.",
    ],
    tourIncludes: [
      "Lunch at a local restaurant",
      "All visit tickets",
      "Professional local guide",
      "Minivan transportation",
      "Travel insurance",
      "Mineral water",
    ],
    tourExcludes: [],
    tourNotes: [
      "Child policy: Children under 4 years old are free. Children 5-9 years old are charged 75% of the adult price. Children 10 years old and above are charged the adult price.",
    ],
    tourImages: [
      "/assets/tours/private/4_hue_city/2.jpg",
      "/assets/tours/private/4_hue_city/3.jpg",
      "/assets/tours/private/4_hue_city/1.jpg",
    ],
  },

  {
    id: "cooking-class-fishing-tour",
    name: "Cooking Class & Fishing Tour",
    price: [
      {
        price: 600000,
        unit: "Person",
        currency: "VND",
        description: "Adults (minimum 2 people per trip)",
      },
      {
        price: 350000,
        unit: "Child",
        currency: "VND",
        description: "Children 4 to 10 years old",
      },
    ],
    tourSchedules: [
      {
        scheduleName: "Morning Tour",
        start: "8:20 AM",
        end: "1:00 PM",
      },
      {
        scheduleName: "Afternoon Tour",
        start: "1:30 PM",
        end: "6:00 PM",
      },
    ],
    tourSummary: [
      "Enjoy a unique cooking class and fishing tour experience in Hoi An. Learn about local ingredients at the market, fish using traditional techniques, and cook delicious local dishes.",
      "Experience traditional fishing customs with bamboo basket boats, explore the water coconut palm forest, and enjoy a cooking class at Tran Long Restaurant.",
    ],
    tourItinerary: [
      "8:30 AM: Our local guides will pick you up at Laluna Hoi An Riverside Hotel.",
      "Transfer to the local market in Hoi An. Learn about different ingredients for your cooking class.",
      "Enjoy a fishing experience by boat on the river. Learn to catch fish with nets and local techniques.",
      "Explore the water coconut palm forest using unique Vietnamese bamboo basket boats and try fishing for crabs.",
      "11:15 AM: Arrive at Tran Long Restaurant and enjoy a local greeting drink.",
      "Participate in a 2-hour cooking class. Our chef will teach you how to cook local dishes.",
      "Enjoy the meal you prepared.",
      "1:15 PM: Return to Laluna Hoi An Riverside Hotel with thanks and best wishes.",
    ],
    tourIncludes: [
      "English-speaking guide (Chef)",
      "Hotel pick-up and return (Only Hoi An old town and the beach areas)",
      "Life jacket",
      "Souvenirs made from water coconut leaves",
      "Water bottle and cold towels",
      "Lunch or dinner",
      "Basket boat to explore nipa (water coconut) forest",
    ],
    tourExcludes: [],
    tourNotes: ["BOOK ONLY ONE COOKING TOUR, YOU WILL HAVE FREE FISHING TOUR"],
    tourImages: [
      "/assets/tours/group/3_cooking_class_and_fishing_tour/4.jpg",
      "/assets/tours/group/3_cooking_class_and_fishing_tour/2.jpg",
      "/assets/tours/group/3_cooking_class_and_fishing_tour/3.jpg",
      "/assets/tours/group/3_cooking_class_and_fishing_tour/1.jpg",
      "/assets/tours/group/3_cooking_class_and_fishing_tour/5.jpg",
    ],
  },
  {
    id: "my-son-holyland-countryside-jeep-tour",
    name: "My Son Holyland & Countryside Jeep Tour",
    price: [
      {
        price: 2376000,
        unit: "Person",
        currency: "VND",
        description: "Per person",
      },
    ],
    tourSchedules: [
      {
        scheduleName: "Morning Tour",
        start: "7:00 AM",
        end: "1:00 PM",
      },
      {
        scheduleName: "Afternoon Tour",
        start: "1:30 PM",
        end: "7:30 PM",
      },
    ],
    tourSummary: [
      "Explore the My Son Sanctuary, one of the highlights of Hoi An, through a unique adventure that takes you through the beautiful countryside in a legendary Army Jeep.",
      "Immerse yourself in the rich cultural heritage and breathtaking natural beauty of this remarkable part of the world.",
    ],
    tourItinerary: [
      "Join us for an unforgettable adventure through the countryside of Hoi An in a legendary Army Jeep, heading towards the My Son Sanctuary.",
      "Explore the historical site of My Son, learn about its cultural significance, and enjoy the natural beauty of the surroundings.",
    ],
    tourIncludes: [],
    tourExcludes: [],
    tourNotes: [],
    tourImages: [
      "/assets/tours/group/4_my_son_jeep_tour/4.jpg",
      "/assets/tours/group/4_my_son_jeep_tour/2.jpg",
      "/assets/tours/group/4_my_son_jeep_tour/3.jpg",
      "/assets/tours/group/4_my_son_jeep_tour/1.jpg",
    ],
  },
  {
    id: "hoi-an-countryside-jeep-tour",
    name: "Hoi An Countryside Jeep Tour",
    price: [
      {
        price: 1439000,
        unit: "Person",
        currency: "VND",
        description: "Per person",
      },
    ],
    tourSchedules: [
      {
        scheduleName: "Morning Tour",
        start: "7:30 AM",
        end: "12:00 PM",
      },
      {
        scheduleName: "Afternoon Tour",
        start: "2:00 PM",
        end: "6:30 PM",
      },
    ],
    tourSummary: [
      "Experience the authentic countryside lifestyle of Vietnam on the Hoi An Countryside Jeep Tour.",
      "Explore rural areas through the back roads, interact with locals, and indulge in home-cooked meals.",
      "Discover traditional occupations and immerse yourself in Vietnamese culture.",
      "An unforgettable adventure that will show you the most charming beauties of Hoi An's countryside.",
    ],
    tourItinerary: [
      "Join us for an authentic countryside experience as we take you through the back roads of rural Vietnam in a legendary Jeep.",
      "Interact with locals, discover traditional occupations, and learn about the Vietnamese way of life.",
      "Enjoy home-cooked meals with local families and immerse yourself in the culture of the region.",
      "End the tour with a delicious lunch (morning tour) or dinner (afternoon tour).",
    ],
    tourIncludes: [],
    tourExcludes: [],
    tourNotes: [],
    tourImages: [
      "/assets/tours/group/5_hoian_country_jeep_tour/5.jpg",
      "/assets/tours/group/5_hoian_country_jeep_tour/8.jpg",
      "/assets/tours/group/5_hoian_country_jeep_tour/1.jpg",
    ],
  },
  {
    id: "marble-mountain-am-phu-cave-monkey-mountain-tour",
    name: "Marble Mountain – Am Phu Cave – Monkey Mountain ",
    price: [
      {
        price: 550000,
        unit: "Person",
        currency: "VND",
        description: "Adults and children 10 years and older",
      },
      {
        price: 412500,
        unit: "Child",
        currency: "VND",
        description: "Children 5 to 9 years",
      },
      {
        price: 0,
        unit: "Child",
        currency: "VND",
        description: "Children under 4 years",
      },
    ],
    tourSchedules: [
      {
        scheduleName: "",
        start: "8:00 AM",
        end: "2:30 PM",
      },
    ],
    tourSummary: [
      "Explore the natural beauty and spiritual significance of Marble Mountain, Am Phu Cave, and Monkey Mountain.",
      "Visit the Marble Mountain, known for its limestone and marble hills, beautiful caves, pagodas, and Buddhist sanctuaries.",
      "Learn about the philosophies of Buddhism in Am Phu Cave and enjoy a panoramic view of Da Nang from Monkey Mountain.",
      "Visit Linh Ung Pagoda, home to the highest Lady Buddha statue in Vietnam, and enjoy a traditional Vietnamese lunch with a local family.",
    ],
    tourItinerary: [
      "8:00 AM: Depart from Laluna Hoi An Riverside Hotel and visit Marble Mountain, also known as Ngu Hanh Son.",
      "Explore the beautiful caves, pagodas, temples, and numerous tunnels within the mountains.",
      "Enjoy a traditional Vietnamese lunch with a local family.",
      "Continue the tour with a visit to Am Phu Cave, learning about the philosophies of Buddhism and the importance of good deeds.",
      "Next, visit Monkey Mountain and enjoy a panoramic view of Da Nang from a high position.",
      "Visit Linh Ung Pagoda, one of the largest pagodas in Da Nang city, and see the highest Lady Buddha statue in Vietnam.",
      "2:30 PM: End of the tour and transfer back to Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "Transportation",
      "English-speaking guide",
      "Vietnamese lunch at a local family",
      "Entrance fees",
      "Water",
    ],
    tourNotes: [
      "Important note: At Marble Mountain, there are many steps. If you have knee or leg problems, or if you are an elderly person, this tour may not be suitable.",
    ],
    tourImages: [
      "/assets/tours/group/6_marble_mountain_amphu_cave_monkey_mountain/4.jpg",
      "/assets/tours/group/6_marble_mountain_amphu_cave_monkey_mountain/1.jpg",
      "/assets/tours/group/6_marble_mountain_amphu_cave_monkey_mountain/2.jpg",
      "/assets/tours/group/6_marble_mountain_amphu_cave_monkey_mountain/3.jpg",
      "/assets/tours/group/6_marble_mountain_amphu_cave_monkey_mountain/5.jpg",
    ],
  },
  {
    id: "hoi-an-village-tour",
    name: "Hoi An Village Tour",
    price: [
      {
        price: 700000,
        unit: "Person",
        currency: "VND",
        description: "Per person",
      },
      {
        price: 350000,
        unit: "Child",
        currency: "VND",
        description: "Children 4 to 11 years old",
      },
      {
        price: 0,
        unit: "Child",
        currency: "VND",
        description: "Children under 4 years",
      },
    ],
    tourSchedules: [
      {
        scheduleName: "Morning Tour",
        start: "8:30 AM",
        end: "1:00 PM",
      },
      {
        scheduleName: "Afternoon Tour",
        start: "2:00 PM",
        end: "7:00 PM",
      },
    ],
    tourSummary: [
      "Experience the authentic village lifestyle of Vietnam on the Hoi An Village Tour. Cycle through beautiful villages, meet local farmers, and participate in traditional farming activities.",
      "Ride a water buffalo, visit a local fishermen family, and enjoy a bamboo basket boat ride through the coconut jungle.",
      "Learn about the Vietnam-American war through interesting and true stories, and enjoy a traditional meal with a local family.",
    ],
    tourItinerary: [
      "Meet the tour guide at Laluna Hoi An Riverside Hotel and cycle to see the beautiful village with rice paddy fields, fish and shrimp farms.",
      "Meet local people working on their farm and join in farming activities such as ploughing the rice paddy field and planting baby rice. Experience riding on a water buffalo.",
      "Visit a local fishermen family, enjoy coconut snacks and green tea, and join a traditional bamboo basket boat ride into the coconut jungle to explore the coconut palm paradise and learn to catch crabs and fish.",
      "Learn about the Vietnam-American war through interesting and true stories that happened during the war.",
      "Have a local lunch at a local family (Mr. Cu's home) and learn how to make rice milk and traditional Hoi An pancakes (Banh Xeo).",
      "Tour ends with drop-off at Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "Traditional lunch for Morning tour or dinner for Afternoon tour",
      "Bottles of water",
      "English-speaking tour guide",
      "Bicycles and Basket Boat transportation",
      "Pick up & drop off at Laluna Hoi An Riverside Hotel",
      "Entrance fee tickets",
    ],
    tourExcludes: [],
    tourNotes: [],
    tourImages: [
      "/assets/tours/group/7_hoian_village_tour/1.png",
      "/assets/tours/group/7_hoian_village_tour/2.jpg",
      "/assets/tours/group/7_hoian_village_tour/3.jpg",
      "/assets/tours/group/7_hoian_village_tour/4.jpg",
      "/assets/tours/group/7_hoian_village_tour/5.jpg",
      "/assets/tours/group/7_hoian_village_tour/6.jpg",
      "/assets/tours/group/7_hoian_village_tour/7.jpg",
      "/assets/tours/group/7_hoian_village_tour/8.jpg",
      "/assets/tours/group/7_hoian_village_tour/9.png",
    ],
  },
  {
    id: "bamboo-basket-boat-tour",
    name: "Bamboo Basket Boat Tour",
    price: [
      {
        price: 14,
        unit: "Person",
        currency: "USD",
        description: "Per person",
      },
    ],
    tourSchedules: [
      {
        start: "7:30 AM",
        end: "5:00 PM",
      },
    ],
    tourSummary: [
      "Experience the charm of the Vietnamese countryside with a Bamboo Basket Boat tour in Cam Thanh village. Enjoy the fresh atmosphere and participate in traditional activities with local people.",
    ],
    tourItinerary: [
      "Our local guides will pick you up at Laluna Hoi An Riverside Hotel and transfer you to Cam Thanh village by minivan.",
      "Arrive at the water coconut palm area and enjoy a welcome with Vietnamese tea and cakes.",
      "Experience a boat trip around the water coconut palm area in a bamboo basket boat with local people.",
      "Participate in activities such as shaking the bamboo basket boat and catching purple crabs in the forest.",
      "Return to Laluna Hoi An Riverside Hotel with big thanks and best wishes.",
    ],
    tourIncludes: [
      "Pick up and drop off at Laluna Hoi An Riverside Hotel",
      "Vietnamese tea and cakes",
      "Bamboo basket boat experience",
      "Local guide",
    ],
    tourExcludes: [],
    tourNotes: [],
    tourImages: [
      "/assets/tours/group/8_basket_boat_tour/3.jpg",
      "/assets/tours/group/8_basket_boat_tour/4.jpg",
      "/assets/tours/group/8_basket_boat_tour/5.jpg",
      "/assets/tours/group/8_basket_boat_tour/2.jpg",
    ],
  },

  /// vespa tours
  {
    id: "hoi-an-countryside-islands-explorer-vespa-tour",
    name: "Hoi An Countryside & Islands Explorer Vespa Tour",
    price: [
      {
        price: 2136000,
        unit: "Person",
        currency: "VND",
        description: "Per person (89 USD)",
      },
    ],
    tourSchedules: [
      {
        start: "8:00 AM",
        end: "1:00 PM",
      },
    ],
    tourSummary: [
      "Escape from the tourist crowds and explore the beautiful countryside of Hoi An on a Vespa. Visit Cam Kim Island, ancestral houses, boat building yards, and enjoy scenic rides through rice paddies and farmland.",
      "Learn about traditional occupations, participate in cottage industry workshops, and enjoy a local cafe stop before heading to the coast.",
      "End the tour with a delicious lunch at a local restaurant and return to Laluna Hoi An Riverside Hotel.",
    ],
    tourItinerary: [
      "Meet at Laluna Hoi An Riverside Hotel and start the Vespa ride to Cam Kim Island.",
      "Visit an ancestral house and a boat building yard to see skilled craftsmen at work.",
      "Ride through rice paddies and farmland, visit cottage industry workshops, and learn to make rice crackers.",
      "Enjoy a cup of Vietnamese drip coffee at a local cafe.",
      "Continue along a scenic road to the coast.",
      "Cross a new bridge with spectacular views over Hoi An and enjoy lunch at a local restaurant.",
      "Return to Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "Local guide",
      "Helmets",
      "Raincoats",
      "Local food and drinks",
      "Insurance",
      "Vespa with experienced driver",
    ],
    tourExcludes: [],
    tourNotes: [],
    tourImages: [
      "/assets/tours/group/9_10_11_12_17_vespa_tour/4_vespa_hoian_countryside/13.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/4_vespa_hoian_countryside/10.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/4_vespa_hoian_countryside/11.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/4_vespa_hoian_countryside/14.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/4_vespa_hoian_countryside/12.jpg",
    ],
  },
  {
    id: "streets-and-eats-of-hoi-an-vespa-tour",
    name: "Streets and Eats of Hoi An Vespa Tour",
    price: [
      {
        price: 2136000,
        unit: "Person",
        currency: "VND",
        description: "Per person (89 USD)",
      },
    ],
    tourSchedules: [
      {
        start: "5:30 PM",
        end: "9:30 PM",
      },
    ],
    tourSummary: [
      "Enjoy a night out on the town, eat and drink at the best local venues, and explore the backstreets of Hoi An.",
      "Start at a local bar, sample street food specialties, and cruise along the riverside to Cam Nam Island.",
      "Visit local restaurants, enjoy a Vietnamese hot pot, and end the tour with a BBQ feast.",
    ],
    tourItinerary: [
      "Start the tour at a local bar with a cold drink and tour briefing.",
      "Sample banh can and 'white rose' dumplings at a local restaurant.",
      "Take a boat ride to Cam Nam Island and enjoy seafood with cold beers.",
      "Ride along river banks and through winding alleys to a lively restaurant for a Vietnamese hot pot.",
      "Finish the tour with a BBQ feast at a special local restaurant and return to Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "Vespa Adventures local guide",
      "Raincoats",
      "Helmets",
      "Local food and drinks (including alcohol)",
      "Insurance",
      "Vespa with experienced driver",
    ],
    tourExcludes: [],
    tourNotes: [],
    tourImages: [
      "/assets/tours/group/9_10_11_12_17_vespa_tour/1_vespa_street_and_eat/7.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/1_vespa_street_and_eat/2.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/1_vespa_street_and_eat/3.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/1_vespa_street_and_eat/4.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/1_vespa_street_and_eat/5.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/1_vespa_street_and_eat/6.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/1_vespa_street_and_eat/1.jpg",
    ],
  },
  {
    id: "rural-villages-experience-vespa-tour",
    name: "Rural Villages Experience Vespa Tour",
    price: [
      {
        price: 1896000,
        unit: "Person",
        currency: "VND",
        description: "Per person (79 USD)",
      },
    ],
    tourSchedules: [
      {
        start: "1:30 PM",
        end: "5:00 PM",
      },
    ],
    tourSummary: [
      "Embark on a Vespa journey discovering Hoi An's villages and countryside.",
      "Visit a vibrant fish market, explore lush landscapes, and learn traditional crafts.",
      "Pause for local snacks, enjoy panoramic views, and witness rural life.",
    ],
    tourItinerary: [
      "Start the tour by cruising along the Thu Bon River to a vibrant fish market.",
      "Visit a family workshop for rice cracker making and explore quiet villages.",
      "Pause for local snacks and enjoy panoramic views.",
      "Ride through rice paddies and return to Hoi An via Cam Kim Bridge, arriving at Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "Vespa Adventures local guide",
      "Raincoats",
      "Helmets",
      "Local food and drinks",
      "Insurance",
      "Vespa with experienced driver",
    ],
    tourExcludes: [],
    tourNotes: [],
    tourImages: [
      "/assets/tours/group/9_10_11_12_17_vespa_tour/2_vespa_rutal_villages/3.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/2_vespa_rutal_villages/2.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/2_vespa_rutal_villages/5.jpg",
    ],
  },
  {
    id: "hai-van-pass-coastal-adventure-vespa-tour",
    name: "Hai Van Pass & Coastal Adventure Vespa Tour",
    price: [
      {
        price: 4296000,
        unit: "Person",
        currency: "VND",
        description: "Per person (179 USD)",
      },
    ],
    tourSchedules: [
      {
        start: "7:30 AM",
        end: "5:00 PM",
      },
    ],
    tourSummary: [
      "Embark on an iconic Vespa ride from Hoi An to historic Hue, traversing picturesque landscapes and capturing Central Vietnam's allure.",
      "Experience the thrill of ascending Hai Van Pass, enjoy local coffee, and explore Lang Co Beach.",
      "Discover rural traditions and cultural heritage along the way, ending the tour in Hue.",
    ],
    tourItinerary: [
      "Start the tour from Laluna Hoi An Riverside Hotel and head along the coast to Da Nang.",
      "Pause at a suspension bridge for stunning vistas and visit a fisherman’s temple.",
      "Ascend Hai Van Pass, relishing panoramic views and exploring historic war sites.",
      "Enjoy local coffee and descend to Lang Co Beach.",
      "Continue to Hue, showcasing rural charms and cultural heritage.",
      "Visit ancestral houses, the 'City of Ghosts', a hilltop Buddhist Pagoda, and Cham Temples.",
      "End the tour in Hue with a visit to a fish-sauce making farm and boat building yard.",
      "Drop off at your hotel in Hue.",
    ],
    tourIncludes: [
      "Vespa Adventures local guide",
      "Luggage transfer",
      "Hotel to hotel by separate vehicle",
      "Raincoats",
      "Entrance fees",
      "Local lunch and drinks",
      "Insurance",
      "Vespa with experienced driver",
      "Helmets",
    ],
    tourExcludes: [],
    tourNotes: [],
    tourImages: [
      "/assets/tours/group/9_10_11_12_17_vespa_tour/3_vespa_haivan_pass/10.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/3_vespa_haivan_pass/4.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/3_vespa_haivan_pass/5.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/3_vespa_haivan_pass/7.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/3_vespa_haivan_pass/6.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/3_vespa_haivan_pass/8.jpg",
      "/assets/tours/group/9_10_11_12_17_vespa_tour/3_vespa_haivan_pass/9.jpg",
    ],
  },
  {
    id: "cham-island-open-tour",
    name: "Cham Island- Snokerling Tour",
    price: [
      {
        price: 700000,
        unit: "Person",
        currency: "VND",
        description: "Per person",
      },
    ],
    tourSchedules: [
      {
        start: "8:00 AM",
        end: "2:30 PM",
      },
    ],
    tourSummary: [
      "Explore the beautiful Cham Island with a full day tour, including visits to historical sites, snorkeling, and a set menu lunch.",
      "Experience the Cham Island Biosphere Reserve, Champa Ancient well, Hai Tang Pagoda, and the local market.",
      "Enjoy snorkeling to admire the underwater beauty, followed by a relaxing time on the beach.",
    ],
    tourItinerary: [
      "8:00 AM - 9:00 AM: Local guide picks you up at Laluna Hoi An Riverside Hotel.",
      "9:00 AM: Depart for Cham Island by Hoi An Green Travel’s speed boat.",
      "Arrive at Cham Island pier and tour 'Bãi Làng' to visit the Cham Island Biosphere Reserve, Champa Ancient well, Hai Tang Pagoda, and the local market.",
      "Learn about Cham Island’s culture, history, and daily lives of the local people.",
      "Transfer to 'Bãi Xếp' or 'Hòn Dài' for snorkeling to admire the underwater scenery.",
      "Enjoy a set menu lunch at a restaurant. Vegetarian or dietary meals are available upon request.",
      "After lunch, take a short rest, swim, and relax on the beach.",
      "2:00 PM - 2:30 PM: Return to Hoi An and transfer back to Laluna Hoi An Riverside Hotel.",
      "Tour ends with farewell and best wishes.",
    ],
    tourIncludes: [
      "Tourist car and speed boat (round trip)",
      "Tour guide",
      "Snorkeling equipment",
      "Lunch at Cham Island",
      "Clean water",
      "Tickets",
    ],
    tourExcludes: [
      "Drinks",
      "Personal expenses",
      "Expenses not in the program",
      "VAT",
    ],
    tourNotes: [
      "You should bring a hat, sunny coat, sandals, sunscreen, and an umbrella.",
      "Note: This tour is weather-dependent. In the event of a typhoon or inclement weather, the tour may not proceed.",
    ],
    tourImages: [
      "/assets/tours/group/13_cham_island/2.jpg",
      "/assets/tours/group/13_cham_island/1.jpg",
      "/assets/tours/group/13_cham_island/3.jpg",
      "/assets/tours/group/13_cham_island/5.jpg",
    ],
  },

  // private tours
  {
    id: "hoi-an-ancient-town-private-tour",
    name: "Hoi An Ancient Town Private Tour",
    price: [
      {
        price: 1695000,
        unit: "Person",
        currency: "VND",
        description: "1 person",
      },
      {
        price: 1525000,
        unit: "Person",
        currency: "VND",
        description: "2 people",
      },
      {
        price: 1375000,
        unit: "Person",
        currency: "VND",
        description: "3-5 people",
      },
      {
        price: 1225000,
        unit: "Person",
        currency: "VND",
        description: "6-10 people",
      },
    ],
    tourSchedules: [
      {
        start: "Flexible Start Time",
        end: "4 hours later",
      },
    ],
    tourSummary: [
      "Take a step back in time on a private, customizable walking tour in Hoi An Ancient Town, a UNESCO World Heritage site. A private guide will lead you through the well-kept historic district of this once-prosperous seaport city.",
      "Explore the narrow lanes lined with centuries-old houses influenced by Chinese, Japanese, French, and European architectures.",
      "Visit numerous places of interest, including the 400-year-old Japanese Covered Bridge, the Phuc Kien Assembly Halls, and the local fish market.",
      "Discover art galleries and tailor shops, and enjoy a meal at a local restaurant followed by a short boat ride to a nearby village specializing in ceramics and crafts.",
    ],
    tourItinerary: [
      "Meet your private guide at Laluna Hoi An Riverside Hotel.",
      "Spend the morning or afternoon enjoying a walking tour around this enchanting port town.",
      "Follow the narrow lanes lined with centuries-old houses influenced by Chinese, Japanese, French, and European architectures.",
      "Visit numerous places of interest, including the 400-year-old Japanese Covered Bridge, the Phuc Kien Assembly Halls, and the local fish market.",
      "Explore art galleries and tailor shops, with the option to get some made-to-order clothes.",
      "Enjoy a light lunch or dinner at a local restaurant.",
      "Take a short boat ride to a nearby village specializing in ceramics and crafts, including boat building.",
      "Return to Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "English-speaking guide",
      "Light lunch or dinner",
      "Entrance ticket",
      "Bottle of water",
    ],
    tourExcludes: [],
    tourNotes: [
      "This private tour can be customized to meet your needs and interests.",
    ],
    tourImages: [
      "/assets/tours/private/1_hoian_city_tour/2.jpeg",
      "/assets/tours/private/1_hoian_city_tour/3.jpeg",
    ],
  },
  {
    id: "hue-city-private-tour",
    name: "Hue City Private Tour",
    price: [
      {
        price: 4795000,
        unit: "Person",
        currency: "VND",
        description: "1 person",
      },
      {
        price: 3255000,
        unit: "Person",
        currency: "VND",
        description: "2 people",
      },
      {
        price: 2995000,
        unit: "Person",
        currency: "VND",
        description: "3-5 people",
      },
      {
        price: 2545000,
        unit: "Person",
        currency: "VND",
        description: "6-10 people",
      },
    ],
    tourSchedules: [
      {
        start: "7:30 AM",
        end: "6:45 PM",
      },
    ],
    tourSummary: [
      "Explore the historic city of Hue on a private, customizable tour. Visit the Hai Van Pass, Lang Co Bay, the Imperial City, Thien Mu Pagoda, and either Tu Duc Tomb or Khai Dinh Tomb.",
      "Enjoy the stunning views, historical sites, and cultural landmarks of this beautiful region with a knowledgeable guide.",
    ],
    tourItinerary: [
      "7:30 AM: The car and tour guide will pick you up at Laluna Hoi An Riverside Hotel and drive to Hai Van Pass, one of the most beautiful passes in Vietnam.",
      "9:30 AM: Short stop at Hai Van Gate for panoramic views of Da Nang city, Lang Co Beach, and more.",
      "10:30 AM: Stop at Lang Co Bay, one of the 30 most beautiful bays in the world, marking the beginning of Thua Thien Hue Province.",
      "11:30 AM: Arrive in Hue.",
      "12:30 PM: After lunch, visit the Imperial City, Thien Mu Pagoda, and either Tu Duc Tomb or Khai Dinh Tomb.",
      "3:00 PM: Depart Hue and head back to Hoi An.",
      "6:45 PM: Arrive at Laluna Hoi An Riverside Hotel and end the tour.",
    ],
    tourIncludes: [
      "English-speaking guide",
      "Entrance ticket",
      "Light lunch",
      "Bottles of water",
      "Cool towel",
    ],
    tourExcludes: [],
    tourNotes: [
      "This private tour can be customized to meet your needs and interests.",
    ],
    tourImages: ["/assets/tours/private/4_hue_city/1.jpg"],
  },
  {
    id: "mystical-marble-mountain-cultural-treasures-private-tour",
    name: "Marble Mountain Cultural Treasures Private Tour",
    price: [
      {
        price: 2515000,
        unit: "Person",
        currency: "VND",
        description: "1 person",
      },
      {
        price: 2095000,
        unit: "Person",
        currency: "VND",
        description: "2 people",
      },
      {
        price: 1785000,
        unit: "Person",
        currency: "VND",
        description: "3-5 people",
      },
      {
        price: 1425000,
        unit: "Person",
        currency: "VND",
        description: "6-10 people",
      },
    ],
    tourSchedules: [
      {
        start: "9:00 AM",
        end: "3:00 PM",
      },
    ],
    tourSummary: [
      "Embark on a journey through history and spirituality with the Mystical Marble Mountain & Cultural Treasures Private Tour. This private, customizable tour takes you to some of the most significant landmarks in the region.",
      "Marvel at the natural beauty and historical significance of Marble Mountain.",
      "Discover the rich cultural heritage at the Cham Museum with its extensive collection of artifacts.",
      "Admire the majestic Lady Buddha statue and take in the breathtaking views of Da Nang.",
    ],
    tourItinerary: [
      "9:00 AM: Tour Guide and driver will pick you up at Laluna Hoi An Riverside Hotel and take you to Marble Mountain.",
      "9:30 AM: Visit Marble Mountain.",
      "11:00 AM: Visit the Cham Museum.",
      "12:30 PM: Enjoy lunch.",
      "1:30 PM: Visit the Lady Buddha statue.",
      "3:00 PM: Return to Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "English-speaking guide",
      "Light lunch",
      "Entrance ticket",
      "Bottle of water",
      "Cool towel",
    ],
    tourExcludes: [],
    tourNotes: [
      "This private tour can be customized to meet your needs and interests.",
      "Cancellation policy: If cancellation is made 1 day prior to your departure date, no fee will be charged. If cancellation is made within half a day, 50% of the rate and tax will be charged.",
    ],
    tourImages: ["/assets/tours/private/2_marble_amphu_monkey/2.jpeg"],
  },
  {
    id: "enchanting-bana-hills-private-tour",
    name: " Bana Hills & Golden Bridge Private Tour",
    price: [
      {
        price: 4015000,
        unit: "Person",
        currency: "VND",
        description: "1 person",
      },
      {
        price: 3415000,
        unit: "Person",
        currency: "VND",
        description: "2 people",
      },
      {
        price: 3075000,
        unit: "Person",
        currency: "VND",
        description: "3-5 people",
      },
      {
        price: 2675000,
        unit: "Person",
        currency: "VND",
        description: "6-10 people",
      },
    ],
    tourSchedules: [
      {
        start: "7:30 AM",
        end: "5:30 PM",
      },
    ],
    tourSummary: [
      "Discover the magical Bana Hills with the Enchanting Bana Hills Private Tour. This private, customizable tour takes you to the heights of Da Nang with breathtaking views and fascinating attractions.",
      "Admire the view from the most modern cable car system in Southeast Asia.",
      "Visit Dream Stream Cable Car Station and enjoy the panorama of Quang Nam – Da Nang City.",
      "Explore the French wine cellar, Le Jardin D’amour, and Linh Ung Pagoda.",
      "Experience the Fantasy Park and explore King Peak at an altitude of 1,487m.",
    ],
    tourItinerary: [
      "7:30 AM - 8:15 AM: The car and tour guide will pick you up at Laluna Hoi An Riverside Hotel and start to visit Ba Na Hills – King Mountain, also known as the second Da Lat/Sapa in Central Vietnam. The beautiful weather here is always cool.",
      "9:30 AM: Admire the view from the most modern cable car system in Southeast Asia.",
      "Visit Dream Stream Cable Car Station and see the panorama of Quang Nam – Da Nang City from high above.",
      "Explore the French wine cellar, Le Jardin D’amour, and Linh Ung Pagoda.",
      "12:00 PM: Have a lunch and rest at the restaurant.",
      "1:00 PM: Visit and play games at the Fantasy Park. Explore King Peak at an altitude of 1,487m.",
      "3:00 PM: Return by cable car.",
      "4:30 PM - 5:30 PM: The car and tour guide will transfer you back to Laluna Hoi An Riverside Hotel.",
      "End of services.",
    ],
    tourIncludes: [
      "English-speaking guide",
      "Light lunch",
      "Entrance ticket",
      "Bottle of water",
      "Cool towel",
    ],
    tourExcludes: [],
    tourNotes: [
      "This private tour can be customized to meet your needs and interests.",
      "Cancellation policy: If cancellation is made 1 day prior to your departure date, no fee will be charged. If cancellation is made within half a day, 50% of the rate and tax will be charged.",
    ],
    tourImages: [
      "/assets/tours/private/7_bana_hills/2.jpg",
      "/assets/tours/private/7_bana_hills/5.jpg",
      "/assets/tours/private/7_bana_hills/6.jpg",
    ],
  },
  {
    id: "ancient-my-son-sanctuary-private-tour",
    name: "Ancient My Son Sanctuary Private Tour",
    price: [
      {
        price: 2245000,
        unit: "Person",
        currency: "VND",
        description: "1 person",
      },
      {
        price: 2075000,
        unit: "Person",
        currency: "VND",
        description: "2 people",
      },
      {
        price: 1865000,
        unit: "Person",
        currency: "VND",
        description: "3-5 people",
      },
      {
        price: 1675000,
        unit: "Person",
        currency: "VND",
        description: "6-10 people",
      },
    ],
    tourSchedules: [
      {
        start: "8:00 AM",
        end: "12:00 PM",
      },
    ],
    tourSummary: [
      "Discover the ancient My Son Sanctuary, a UNESCO World Heritage site, with the Ancient My Son Sanctuary Private Tour. This private, customizable tour offers a deep dive into the history and architecture of the Champa Kingdom.",
      "Explore the My Son temples, built between the 4th and 14th centuries, reflecting the architectural and cultural influences of Indian culture.",
      "Learn about the historical significance and unique features of each temple from your knowledgeable guide.",
    ],
    tourItinerary: [
      "8:00 AM: Pick up at Laluna Hoi An Riverside Hotel.",
      "9:30 AM: Arrive at My Son Holy Land. Spend 2 hours exploring and discovering the beauty of the ancient buildings of the Champa Kingdom with our tour guide.",
      "12:00 PM: Leave My Son and return to Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "English-speaking guide",
      "Entrance ticket",
      "Light lunch",
      "Bottle of water",
      "Cool towel",
      "30-minute foot massage",
    ],
    tourExcludes: [],
    tourNotes: [
      "This private tour can be customized to meet your needs and interests.",
      "Cancellation policy: If cancellation is made 1 day prior to your departure date, no fee will be charged. If cancellation is made within half a day, 50% of the rate and tax will be charged.",
    ],
    tourImages: ["/assets/tours/private/3_myson_holand/2.jpg"],
  },
  {
    id: "hoi-an-craft-villages-city-private-jeep-tour",
    name: "Hoi An Craft Villages and City- Private Jeep Tour",
    price: [
      {
        price: 3425714,
        unit: "Person",
        currency: "VND",
        description: "1 person",
      },
      {
        price: 1712857,
        unit: "Person",
        currency: "VND",
        description: "2 people",
      },
    ],
    tourSchedules: [
      {
        start: "Flexible Start Time",
        end: "5 hours later",
      },
    ],
    tourSummary: [
      "Experience the charm of Hoi An's countryside and traditional craft villages with the Hoi An Craft Villages and City Private Jeep Tour. This private, customizable tour takes you through scenic village roads, picturesque rice fields, and Cam Kim island.",
      "Create your own dishes at a local house crafting rice water fern cakes and rice pancakes.",
      "Discover the art of traditional mat-weaving, visit a fishing village, and enjoy coffee by the riverbank amidst stunning natural vistas.",
      "Explore the serene coconut forest on a basket boat and engage in buffalo riding in the fields.",
      "Visit Tra Que vegetable village to witness vegetable cultivation and understand local lifestyles.",
    ],
    tourItinerary: [
      "Start the Jeep tour with a pick-up at Laluna Hoi An Riverside Hotel.",
      "Drive through scenic village roads, picturesque rice fields, and onto Cam Kim island.",
      "Stop at a local house to craft rice water fern cakes and rice pancakes. Create your own dishes and savor the results.",
      "Discover the art of traditional mat-weaving at a local house.",
      "Visit a fishing village to gain insights into local livelihoods.",
      "Pause by the riverbank for coffee amidst stunning natural vistas.",
      "Explore the serene coconut forest and experience a basket boat ride.",
      "Engage in buffalo riding in the fields.",
      "Visit Tra Que vegetable village to witness vegetable cultivation and understand local lifestyles.",
      "Return to Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "Jeep car",
      "Bottle of water",
      "Cold towel",
      "English-speaking guide",
    ],
    tourExcludes: [],
    tourNotes: [
      "This private tour can be customized to meet your needs and interests.",
      "Please wear comfortable clothing and bring sunscreen and a hat.",
    ],
    tourImages: [
      "/assets/tours/private/8_jeep_hoian/2.jpg",
      "/assets/tours/private/8_jeep_hoian/9.jpg",
    ],
  },
  {
    id: "hoi-an-my-son-private-jeep-tour",
    name: "Hoi An to My Son Private Jeep Tour",
    price: [
      {
        price: 3802857,
        unit: "Person",
        currency: "VND",
        description: "1 person",
      },
      {
        price: 1901428,
        unit: "Person",
        currency: "VND",
        description: "2 people",
      },
    ],
    tourSchedules: [
      {
        start: "Flexible Start Time",
        end: "5 hours later",
      },
    ],
    tourSummary: [
      "Discover the ancient My Son Sanctuary with the Hoi An to My Son Private Jeep Tour. This private, customizable tour takes you through the back roads of Hoi An countryside to the historic My Son site.",
      "Enjoy a traditional performance by Cham dancers as an introduction to their culture.",
      "Explore the remarkable towers constructed by Cham builders many centuries ago with a knowledgeable guide.",
    ],
    tourItinerary: [
      "Start the Jeep tour with a pick-up at Laluna Hoi An Riverside Hotel.",
      "Drive through the scenic back roads of Hoi An countryside to My Son Sanctuary.",
      "Arrive at My Son Sanctuary and enjoy a traditional performance by Cham dancers.",
      "Explore the ancient towers with your guide, learning about their historical significance.",
      "Return to Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "Jeep car",
      "Bottle of water",
      "Cold towel",
      "English-speaking guide",
    ],
    tourExcludes: [],
    tourNotes: [
      "This private tour can be customized to meet your needs and interests.",
      "Please wear comfortable clothing and bring sunscreen and a hat.",
    ],
    tourImages: [
      "/assets/tours/private/9_jeep_hoian_myson/6.jpg",
      "/assets/tours/private/9_jeep_hoian_myson/2.jpg",
      "/assets/tours/private/9_jeep_hoian_myson/4.jpg",
    ],
  },
  {
    id: "son-tra-linh-ung-pagoda-private-jeep-tour",
    name: "Son Tra and Linh Ung Pagoda Private Jeep Tour",
    price: [
      {
        price: 4902857,
        unit: "Person",
        currency: "VND",
        description: "1 person",
      },
      {
        price: 2451428,
        unit: "Person",
        currency: "VND",
        description: "2 people",
      },
    ],
    tourSchedules: [
      {
        start: "Flexible Start Time",
        end: "5 hours later",
      },
    ],
    tourSummary: [
      "Explore the natural beauty and cultural landmarks of Son Tra with the Son Tra and Linh Ung Pagoda Private Jeep Tour. This private, customizable tour takes you through the stunning landscapes of Son Tra peninsula.",
      "Visit Vong Canh House, the helipad, the top of the chessboard, and Linh Ung Pagoda.",
      "Encounter the rare douc langur and enjoy coffee at the peak of the pass.",
    ],
    tourItinerary: [
      "Start the tour with a pick-up at Laluna Hoi An Riverside Hotel and travel via a 16-seat car to Son Tra Da Nang area.",
      "Transfer to a Jeep to start exploring the Son Tra peninsula.",
      "Visit Vong Canh House, the helipad, the top of the chessboard, and Linh Ung Pagoda.",
      "Encounter the rare douc langur, a Vietnam classified species.",
      "Pause to savor coffee at the peak of the pass.",
      "Return to Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "Jeep car",
      "Bottle of water",
      "Cold towel",
      "English-speaking guide",
    ],
    tourExcludes: [],
    tourNotes: [
      "This private tour can be customized to meet your needs and interests.",
      "Please wear comfortable clothing and bring sunscreen and a hat.",
    ],
    tourImages: [
      "/assets/tours/private/10_jeep_hoian_sontra_linhung/4.jpg",
      "/assets/tours/private/10_jeep_hoian_sontra_linhung/2.jpg",
      "/assets/tours/private/10_jeep_hoian_sontra_linhung/3.jpg",
      "/assets/tours/private/10_jeep_hoian_sontra_linhung/5.jpg",
    ],
  },
  {
    id: "hoi-an-private-cycling-tour",
    name: "Hoi An Cycling Private Tour",
    price: [
      {
        price: 1795000,
        unit: "Person",
        currency: "VND",
        description: "1 person",
      },
      {
        price: 1345000,
        unit: "Person",
        currency: "VND",
        description: "2 people",
      },
      {
        price: 1095000,
        unit: "Person",
        currency: "VND",
        description: "3-5 people",
      },
      {
        price: 995000,
        unit: "Person",
        currency: "VND",
        description: "6-10 people",
      },
    ],
    tourSchedules: [
      {
        scheduleName: "Morning Tour",
        start: "8:00 AM",
        end: "11:00 AM",
      },
      {
        scheduleName: "Afternoon Tour",
        start: "2:00 PM",
        end: "5:00 PM",
      },
    ],
    tourSummary: [
      "Experience the charm of Hoi An's countryside with the Hoi An Private Cycling Tour. This private, customizable tour takes you through peaceful rice fields and traditional villages.",
      "Visit Cam Thanh Village and explore the Coconut Palm Paradise on a unique basket boat.",
      "Cycle to Tra Que Village and learn about local crafts from the villagers.",
    ],
    tourItinerary: [
      "Start the cycling tour from Laluna Hoi An Riverside Hotel, traveling through the peaceful countryside via rice fields.",
      "Visit Cam Thanh Village and explore the Coconut Palm Paradise with a unique round boat ('Thung Chai').",
      "Continue cycling to Tra Que Village and learn how to make traditional products with the help of local people.",
      "Return to Laluna Hoi An Riverside Hotel or the Ancient Town.",
    ],
    tourIncludes: [
      "English-speaking guide",
      "Basket boat ride (30-45 minutes)",
      "Bottle of water",
      "Cool towel",
      "All entrance fees",
    ],
    tourExcludes: [],
    tourNotes: [
      "This private tour can be customized to meet your needs and interests.",
      "Please wear comfortable clothing and bring sunscreen and a hat.",
    ],
    tourImages: [
      "/assets/tours/private/5_cycling_1/1.jpg",
      "/assets/tours/private/5_cycling_1/2.jpg",
      "/assets/tours/private/5_cycling_1/4.jpg",
    ],
  },
  {
    id: "village-experience-private-cycling-tour",
    name: "Village Experience Cycling Private Tour",
    price: [
      {
        price: 1955000,
        unit: "Person",
        currency: "VND",
        description: "1 person",
      },
      {
        price: 1735000,
        unit: "Person",
        currency: "VND",
        description: "2 people",
      },
      {
        price: 1545500,
        unit: "Person",
        currency: "VND",
        description: "3-5 people",
      },
      {
        price: 1395000,
        unit: "Person",
        currency: "VND",
        description: "6-10 people",
      },
    ],
    tourSchedules: [
      {
        start: "8:00 AM",
        end: "1:00 PM",
      },
    ],
    tourSummary: [
      "Discover the vibrant culture and beautiful landscapes of Hoi An with the Village Experience Private Cycling Tour. This private, customizable tour offers a deep dive into local life.",
      "Cycle through rice fields and visit Cam Thanh Village to explore the Coconut Palm Paradise on a basket boat.",
      "Enjoy lunch with a local family and learn about their daily lives.",
      "Visit Tra Que Village to see traditional crafts and understand local lifestyles.",
    ],
    tourItinerary: [
      "Start the cycling tour from Laluna Hoi An Riverside Hotel, traveling through the peaceful countryside via rice fields.",
      "Visit Cam Thanh Village and explore the Coconut Palm Paradise with a unique round boat ('Thung Chai').",
      "Have lunch with a local family and learn about their daily lives.",
      "Continue cycling to Tra Que Village and learn how to make traditional products with the help of local people.",
      "Return to Laluna Hoi An Riverside Hotel or the Ancient Town.",
    ],
    tourIncludes: [
      "English-speaking guide",
      "Basket boat ride (30-45 minutes)",
      "Lunch with a local family",
      "Bottle of water",
      "Cool towel",
      "All entrance fees",
    ],
    tourExcludes: [],
    tourNotes: [
      "This private tour can be customized to meet your needs and interests.",
      "Please wear comfortable clothing and bring sunscreen and a hat.",
    ],
    tourImages: [
      "/assets/tours/private/6_cycling_2/4.jpg",
      "/assets/tours/private/6_cycling_2/1.jpg",
      "/assets/tours/private/6_cycling_2/3.jpg",
      "/assets/tours/private/6_cycling_2/4.jpg",
    ],
  },
  {
    id: "hoi-an-city-private-walking-tour",
    name: "Hoi An City Walking Private Tour",
    price: [
      {
        price: 1695000,
        unit: "Person",
        currency: "VND",
        description: "1 person",
      },
      {
        price: 1525000,
        unit: "Person",
        currency: "VND",
        description: "2 people",
      },
      {
        price: 1375000,
        unit: "Person",
        currency: "VND",
        description: "3-5 people",
      },
      {
        price: 1225000,
        unit: "Person",
        currency: "VND",
        description: "6-10 people",
      },
    ],
    tourSchedules: [
      {
        start: "Flexible Start Time",
        end: "4 hours later",
      },
    ],
    tourSummary: [
      "Take a step back in time on a private, customizable walking tour in Hoi An Ancient Town, a UNESCO World Heritage site. A private guide will lead you through the well-kept historic district of this once-prosperous seaport city.",
      "Explore the narrow lanes lined with centuries-old houses influenced by Chinese, Japanese, French, and European architectures.",
      "Visit numerous places of interest, including the 400-year-old Japanese Covered Bridge, the Phuc Kien Assembly Halls, and the local fish market.",
      "Discover art galleries and tailor shops, and enjoy a meal at a local restaurant followed by a short boat ride to a nearby village specializing in ceramics and crafts.",
    ],
    tourItinerary: [
      "Meet your private guide at Laluna Hoi An Riverside Hotel.",
      "Spend the morning or afternoon enjoying a walking tour around this enchanting port town.",
      "Follow the narrow lanes lined with centuries-old houses influenced by Chinese, Japanese, French, and European architectures.",
      "Visit numerous places of interest, including the 400-year-old Japanese Covered Bridge, the Phuc Kien Assembly Halls, and the local fish market.",
      "Explore art galleries and tailor shops, with the option to get some made-to-order clothes.",
      "Enjoy a light lunch or dinner at a local restaurant.",
      "Take a short boat ride to a nearby village specializing in ceramics and crafts, including boat building.",
      "Return to Laluna Hoi An Riverside Hotel.",
    ],
    tourIncludes: [
      "English-speaking guide",
      "Light lunch or dinner",
      "Entrance ticket",
      "Bottle of water",
    ],
    tourExcludes: [],
    tourNotes: [
      "This private tour can be customized to meet your needs and interests.",
      "Cancellation policy: If cancellation is made 1 day prior to your departure date, no fee will be charged. If cancellation is made within half a day, 50% of the rate and tax will be charged.",
    ],
    tourImages: ["/assets/tours/private/1_hoian_city_tour/1.jpg"],
  },
];

const getTourData = () => {
  return ToursList;
};

const getTour = (id) => {
  const found = ToursList.find((tour) => tour.id === id);
  return found;
};

module.exports = { getTourData, getTour };
