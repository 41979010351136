module.exports = {
  name: "Spa",
  sections: [
    {
      name: "Laluna Signature Packages",
      images: [
        "/assets/spa/spa-services/body_treatment_section_2_1.jpg",
        "/assets/spa/spa-services/body_treatment_section_2_2_.jpg",
      ],
      items: [
        {
          name: "Embrace Relaxation",
          description:
            "A combination of Hot Stone Full Body Massage using warm smooth stones followed by a refreshing facial pampering (Hot Stone Body Massage 60 minutes, Refresh Facial 30 minutes).",
          options: [
            {
              description: "90 minutes",
              price: 1185000,
            },
          ],
        },
        {
          name: "Indulge in Luxury",
          description:
            "Hot Stone Full Body Massage combined with a soothing foot scrub (Hot Stone Body Massage 60 minutes, Foot Care 30 minutes).",
          options: [
            {
              description: "90 minutes",
              price: 950000,
            },
          ],
        },
        {
          name: "Premium Wellness Package",
          description:
            "This luxurious face and foot ritual stimulates energy flow, eliminates toxins, and tones facial muscles. It also promotes a feeling of pure relaxation that extends from head to toe, leaving you deeply revitalized and soothed. A perfect getaway to escape and de-stress from daily life.",
          options: [
            {
              description: "75 minutes",
              price: 1530000,
            },
          ],
        },
        {
          name: "Serenity Spa Day Package",
          description:
            "An unforgettable half-day package consisting of five treatments created by Laluna Spa. Begin with a sauna or herbal bath. Saunas help relieve stress, relax aching muscles, cleanse the skin, and improve cardiovascular performance, while a local herbal bath helps improve blood circulation, sleep, and breathing. This is followed by a Relaxation Treatment combining massage with essential oils. Next comes Laluna Spa Body Scrub to cleanse and exfoliate the skin, concluding with a moisturizing face mask leaving your skin smooth and glowing. The Serenity Spa Day Package is a wonderful treat for the mind, body, and soul.",
          options: [
            {
              description: "4 hours",
              price: 2600000,
            },
          ],
        },
      ],
    },
    {
      name: "Relaxing Moment",
      images: [
        "/assets/spa/spa-services/body_treatment_section_3_1.jpg",
        "/assets/spa/spa-services/body_treatment_section_1_1.jpg",
      ],
      items: [
        {
          name: "Head, Neck, Back, and Shoulder Massage",
          description:
            "A focused massage targeting tension and stress in the head, neck, back, and shoulders. This treatment helps relieve muscle tightness, reduce headaches, and promote deep relaxation.",
          options: [
            {
              description: "45 minutes",
              price: 480000,
            },
          ],
        },
        {
          name: "Head, Neck, Shoulder, and Hand Massage",
          description:
            "An invigorating massage concentrating on the head, neck, shoulders, and hands. This therapy alleviates tension, improves circulation, and soothes tired muscles for overall well-being.",
          options: [
            {
              description: "30 minutes",
              price: 350000,
            },
          ],
        },
        {
          name: "Foot Relaxation Massage",
          description:
            "A soothing foot massage that relieves tired feet, reduces stress, and promotes relaxation through the stimulation of pressure points and gentle massage techniques.",
          options: [
            {
              description: "30 minutes",
              price: 350000,
            },
          ],
        },
      ],
    },
    {
      name: "Facial",
      images: [
        "/assets/spa/spa-services/facial_1.jpg",
        "/assets/spa/spa-services/facial_2.jpg",
      ],
      items: [
        {
          name: "Basic Facial Care",
          description:
            "A reviving facial which includes a deep cleanse, exfoliation, soothing facial massage, and finally a moisturizing treatment using the finest skincare products, leaving your skin refreshed and nourished.",
          options: [
            {
              description: "45 minutes",
              price: 490000,
            },
          ],
        },
        {
          name: "Natural Skin Dream",
          description:
            "This facial has been described as an experience in its own right. Using a natural yogurt-based face mask, this all-in-one facial cleanses, soothes, nourishes, and balances the skin, leaving it radiant and glowing.",
          options: [
            {
              description: "60 minutes",
              price: 650000,
            },
          ],
        },
        {
          name: "Silky Beauty",
          description:
            "Begin with a skin cleanse and massage followed by the application of a thin, rich, velvety cream mask. A head massage stimulates the nerves and blood vessels beneath the skin whilst also calming muscle tension. The cream mask is then removed, and your face is moisturized, leaving the skin soft and silky smooth.",
          options: [
            {
              description: "60 minutes",
              price: 650000,
            },
          ],
        },
        {
          name: "Lifting Feather",
          description:
            "Experience complete relaxation with a Vietnamese manual facelift technique. The clay mask is then applied to draw out excess oil from the pores while retaining moisture and hydration. This facial treatment is tailored to make your skin feel light as a feather.",
          options: [
            {
              description: "60 minutes",
              price: 650000,
            },
          ],
        },
        {
          name: "Anti-Aging Facial Treatment with Collagen Mask",
          description:
            "The extra collagen mask penetrates into the skin, resulting in wrinkle attenuation and a smoother, firmer, more youthful appearance.",
          options: [
            {
              description: "75 minutes",
              price: 750000,
            },
          ],
        },
      ],
    },
    {
      name: "Body Massage",
      images: [
        "/assets/spa/spa-services/packages_section_1_1.jpg",
        "/assets/spa/spa-services/body_treatment_section_1_2.jpg",
      ],
      items: [
        {
          name: "Relaxation Treatment",
          description:
            "Applying gentle soothing massage techniques with local aromatic oils, the aim is to remove overall tension, relieve swollen or painful joints, and balance one's emotions.",
          options: [
            {
              description: "60 minutes",
              price: 580000,
            },
            {
              description: "90 minutes",
              price: 870000,
            },
          ],
        },
        {
          name: "Tension Relief Therapy",
          description:
            "Using strong massage techniques combined with essential oils, this treatment targets key muscle groups to relieve aches and pains. Smooth, flowing rhythmic movements also help improve circulation and stimulate an overall sense of well-being and balance.",
          options: [
            {
              description: "60 minutes",
              price: 630000,
            },
            {
              description: "90 minutes",
              price: 870000,
            },
          ],
        },
        {
          name: "Traditional Thai Massage",
          description:
            "This therapy originated in Thailand. It is a traditional healing system combining acupressure, stretching, rhythmic pressure on the muscles, and yoga-like positions to get a refreshing rush of energy.",
          options: [
            {
              description: "60 minutes",
              price: 695000,
            },
            {
              description: "90 minutes",
              price: 900000,
            },
          ],
        },
        {
          name: "Vietnamese Bamboo Body Massage",
          description:
            "Vietnamese Bamboo Body Massage is a perfect combination of bamboo and massage techniques. The therapist uses heated bamboo sticks to directly impact muscle tissues and joints, employing techniques like massage, rolling, and tapping in various directions to improve health and address muscle problems, reduce pain, and bring deep relaxation.",
          options: [
            {
              description: "75 minutes",
              price: 825000,
            },
            {
              description: "90 minutes",
              price: 1045000,
            },
          ],
        },
      ],
    },
    {
      name: "Special Massages",
      images: [
        "/assets/spa/spa-services/packages_section_1_1.jpg",
        "/assets/spa/spa-services/body_treatment_section_1_2.jpg",
      ],
      items: [
        {
          name: "Hot Stone Massage",
          description:
            "Hot Stone Massage melts away muscle tension and stress through the application of warm smooth stones placed on key areas of the body to heat the muscles, promoting an overall relaxed sense of well-being and calm.",
          options: [
            {
              description: "75 minutes",
              price: 825000,
            },
            {
              description: "90 minutes",
              price: 1045000,
            },
          ],
        },
        {
          name: "Laluna Herbal Hot Therapy",
          description:
            "Our therapist begins your treatment with essential oils to warm the body, then applies heated herbs rubbed lightly on the skin to open the pores and bring heat to the muscles, helping relieve pain.",
          options: [
            {
              description: "75 minutes",
              price: 750000,
            },
            {
              description: "90 minutes",
              price: 945000,
            },
          ],
        },
        {
          name: "Pregnancy Massage",
          description:
            "This treatment not only provides relaxation but also brings many benefits during pregnancy, such as relieving stress, improving blood circulation, and reducing insomnia, edema, and headaches; it will help you feel refreshed and comfortable.",
          options: [
            {
              description: "60 minutes",
              price: 580000,
            },
            {
              description: "90 minutes",
              price: 870000,
            },
          ],
        },
        {
          name: "Laluna Spa Four-Hands Massage",
          description:
            "Two therapists work in choreographed, synchronized movements, applying soothing touch to the skin, leading to complete and total relaxation. The body falls into a state of optimal rest, releasing aches and muscle tension, improving blood circulation, and reducing stress and anxiety. The Laluna Spa team has perfected the technique, making our Four-Hands Massage particularly special.",
          options: [
            {
              description: "75 minutes",
              price: 1390000,
            },
          ],
        },
      ],
    },
    {
      name: "Foot Treatments",
      images: [
        "/assets/spa/spa-services/packages_section_1_1.jpg",
        "/assets/spa/spa-services/body_treatment_section_1_2.jpg",
      ],
      items: [
        {
          name: "Foot Reflexology",
          description:
            "Experience overall healing and well-being as your therapist focuses on the pressure points of your feet using deep tissue massage with essential oils. The oils leave your skin soft and smooth.",
          options: [
            {
              description: "60 minutes",
              price: 520000,
            },
          ],
        },
        {
          name: "Foot Remedy",
          description:
            "A foot massage using hot stones applied to your legs and feet. The combination of pressure massage with the warmth of hot stones instills a sense of relaxation, helping to restore, rebalance, and rejuvenate the body.",
          options: [
            {
              description: "60 minutes",
              price: 555000,
            },
          ],
        },
        {
          name: "Total Foot Treatment",
          description:
            "A complete care package and ultimate treat for feet. This treatment combines a foot massage focusing on pressure points with a dry and wet scrub to remove dead skin, leaving your feet silky smooth. It is an invigorating 'facelift' for your feet.",
          options: [
            {
              description: "75 minutes",
              price: 650000,
            },
          ],
        },
      ],
    },
    {
      name: "Body Scrub Options",
      images: [
        "/assets/spa/spa-services/body_exfoliate_1.jpg",
        "/assets/spa/spa-services/body_exfoliate_2.jpg",
      ],
      items: [
        {
          name: "Laluna Spa Body Scrub",
          description:
            "Laluna Spa's moisturizing body scrub provides a deep cleansing and exfoliating treatment. Dead skin cells are removed while the growth of healthy new ones is promoted. Mineral sea salt and olive oil help to remove impurities and stimulate blood circulation.",
          options: [
            {
              description: "60 minutes",
              price: 590000,
            },
          ],
        },
        {
          name: "Honey and Sesame Body Scrub",
          description:
            "For a relaxing route to soft skin, this body scrub exfoliates the accumulation of dead skin cells which dulls skin. Other benefits include increasing blood circulation and extra smooth skin through the honey's nourishment and moisturization. The treatment is completed after a shower and mini massage, and an organic body moisturizer will be applied.",
          options: [
            {
              description: "60 minutes",
              price: 590000,
            },
          ],
        },
        {
          name: "Coffee and Orange Body Scrub",
          description:
            "If you swoon when you pass a coffee bar, imagine that smell when the most fragrant of blends is smothered over the body. This invigorating scrub utilizes the natural acids in coffee to remove dead skin cells and smooth away roughness while rice and milk combine to tone tired skin. The treatment is completed after a shower and mini massage, and an organic body moisturizer will be applied.",
          options: [
            {
              description: "60 minutes",
              price: 590000,
            },
          ],
        },
      ],
    },
    {
      name: "Body Wrap Options",
      images: [
        "/assets/spa/spa-services/body_exfoliate_1.jpg",
        "/assets/spa/spa-services/body_exfoliate_2.jpg",
      ],
      items: [
        {
          name: "Mud and Milk Body Wrap",
          description:
            "Harnessing the power of natural vitamins and minerals, this body wrap helps improve the appearance and feel of skin. It draws toxins from the body and helps hydrate, tone, and smooth the skin.",
          options: [
            {
              description: "60 minutes",
              price: 590000,
            },
          ],
        },
        {
          name: "Aloe Vera and Yogurt Body Wrap",
          description:
            "Aloe vera, with its high amino acid content, rejuvenates tired, dull skin. Furthermore, it is the ideal remedy for sunburn treatment to relieve the skin and give a comforting feeling.",
          options: [
            {
              description: "60 minutes",
              price: 590000,
            },
          ],
        },
        {
          name: "Botanical Body Wrap",
          description:
            "This exquisite body wrap enriched with natural honey, oatmeal, and fresh milk provides a moisturizing treatment to balance and soften your skin.",
          options: [
            {
              description: "60 minutes",
              price: 590000,
            },
          ],
        },
      ],
    },
    {
      name: "Experience the Best of Laluna Spa - Special Packages",
      images: [
        "/assets/spa/spa-services/packages_section_3_2.jpg",
        "/assets/spa/spa-services/packages_section_1_2.jpg",
        "/assets/spa/spa-services/packages_section_1_3.jpg",
      ],
      items: [
        {
          name: "Forest Package",
          description: [
            "Laluna Spa Body Scrub 45 minutes",
            "Foot Rest Massage 30 minutes",
            "Basic Facial Care 45 minutes",
          ],
          options: [
            {
              price: 1220000,
            },
          ],
        },
        {
          name: "Ocean Package",
          description: [
            "Laluna Spa Body Scrub 45 minutes",
            "Laluna Herbal Hot Therapy 60 minutes",
            "Natural Skin Dream 60 minutes",
          ],
          options: [
            {
              price: 1590000,
            },
          ],
        },
        {
          name: "Earth Package",
          description: [
            "Tension Relief Therapy 90 minutes",
            "Coffee & Orange Scrub 45 minutes",
            "Mud & Milk Body Wrap 45 minutes",
            "Sauna 20 minutes",
          ],
          options: [
            {
              price: 2040000,
            },
          ],
        },
        {
          name: "Sky Package",
          description: [
            "Laluna Spa Body Scrub 45 minutes",
            "Relaxation Treatment 90 minutes",
            "Lifting Feather 45 minutes",
            "Manicure or Pedicure Gel Color 45 minutes",
          ],
          options: [
            {
              price: 2100000,
            },
          ],
        },
      ],
    },
    {
      name: "Manicure Nail Services",
      description:
        "Professional application of nail polish to enhance the beauty of your fingernails. Choose from a variety of colors to suit your style.",
      images: [
        "/assets/spa/spa-services/manicure_1.jpg",
        "/assets/spa/spa-services/manicure_2.jpg",
        "/assets/spa/spa-services/manicure_3.jpg",
      ],
      items: [
        {
          name: "Cuticle Work for Hands without Nail Polish",
          description:
            "Nail trimming, shaping, hand soak, cuticle grooming, and nail buffing.",
          options: [
            {
              description: "20 minutes",
              price: 170000,
            },
          ],
        },
        {
          name: "Fingernail Polish Application",
          description:
            "Professional application of nail polish to enhance the beauty of your fingernails. Choose from a variety of colors to suit your style.",
          options: [
            {
              description: "20 minutes",
              price: 150000,
            },
          ],
        },
        {
          name: "Manicure with Nail Polish",
          description:
            "Nail trimming, shaping, hand soak, cuticle grooming, nail buffing, and nail polish.",
          options: [
            {
              description: "45 minutes",
              price: 250000,
            },
          ],
        },
        {
          name: "Manicure with Gel Polish",
          description:
            "Nail trimming, shaping, hand soak, cuticle grooming, nail buffing, and gel color.",
          options: [
            {
              description: "45 minutes",
              price: 370000,
            },
          ],
        },
        {
          name: "Laluna Signature Manicure",
          description:
            "Nail trimming, shaping, hand soak, hand scrub, cuticle grooming, nail buffing, hand massage, and nail polish.",
          options: [
            {
              description: "60 minutes",
              price: 370000,
            },
          ],
        },
        {
          name: "Children's Manicure",
          description:
            "A fun and gentle manicure designed for children aged 12 and under.",
          options: [
            {
              description: "20 minutes",
              price: 150000,
            },
          ],
        },
      ],
    },
    {
      name: "Pedicure Nail Services",
      images: [
        "/assets/spa/spa-services/pedicure_1.jpg",
        "/assets/spa/spa-services/pedicure_2.jpg",
        "/assets/spa/spa-services/pedicure_3.jpg",
      ],
      items: [
        {
          name: "Cuticle Work for Feet without Nail Polish",
          description:
            "Nail trimming, shaping, foot soak, cuticle grooming, and nail buffing.",
          options: [
            {
              description: "20 minutes",
              price: 170000,
            },
          ],
        },
        {
          name: "Toenail Polish Application",
          description:
            "Professional application of nail polish to beautify your toenails. Select from a range of vibrant colors for a polished look.",
          options: [
            {
              description: "20 minutes",
              price: 150000,
            },
          ],
        },
        {
          name: "Pedicure with Nail Polish",
          description:
            "Nail trimming, shaping, foot soak, cuticle grooming, nail buffing, and nail polish.",
          options: [
            {
              description: "45 minutes",
              price: 250000,
            },
          ],
        },
        {
          name: "Pedicure with Gel Polish",
          description:
            "Nail trimming, shaping, foot soak, cuticle grooming, nail buffing, and gel color.",
          options: [
            {
              description: "45 minutes",
              price: 370000,
            },
          ],
        },
        {
          name: "Laluna Pedicure",
          description:
            "Nail trimming, shaping, foot soak, foot scrub, heel scrub, cuticle grooming, nail buffing, foot massage, and nail polish.",
          options: [
            {
              description: "60 minutes",
              price: 370000,
            },
          ],
        },
        {
          name: "Children's Pedicure",
          description:
            "A fun and gentle pedicure designed for children aged 12 and under.",
          options: [
            {
              description: "20 minutes",
              price: 150000,
            },
          ],
        },
      ],
    },
    {
      name: "Additional Services",
      images: [
        "/assets/spa/spa-services/waxing_1.jpg",
        "/assets/spa/spa-services/waxing_2.jpg",
      ],
      items: [
        {
          name: "Gel Polish Removal",
          description:
            "Safe and professional removal of gel polish to maintain the health and integrity of your natural nails.",
          options: [
            {
              price: 170000,
            },
          ],
        },
        {
          name: "Heel Scrub",
          description:
            "A gentle exfoliation treatment to remove dead skin cells and calluses from the heels, leaving your feet soft and smooth.",
          options: [
            {
              description: "40 minutes",
              price: 200000,
            },
          ],
        },
        {
          name: "Nail Art Design",
          description:
            "Express your creativity with a unique and stylish nail art design.",
          options: [
            {
              price: 110000,
            },
          ],
        },
        {
          name: "Customized Nail Design",
          description:
            "A personalized nail design tailored to your preferences and style. Prices range from 550,000 to 900,000, depending on design complexity.",
          options: [
            {
              description: "Minimum",
              price: 550000,
            },
            {
              description: "Maximum",
              price: 900000,
            },
          ],
        },
      ],
    },
    {
      name: "Hair Washing",
      images: [
        "/assets/spa/spa-services/packages_section_3_1.jpg",
        "/assets/spa/spa-services/facial_3.jpg",
      ],
      items: [
        {
          name: "Hair Washing with Head, Neck, and Shoulder Massage",
          description:
            "Enjoy a refreshing hair wash followed by a relaxing head, neck, and shoulder massage. This treatment relieves tension and leaves you feeling revitalized.",
          options: [
            {
              description: "45 minutes",
              price: 450000,
            },
          ],
        },
        {
          name: "Hair Washing with Facial Cleansing",
          description:
            "Experience a thorough hair wash coupled with a gentle facial cleansing. This service cleanses and rejuvenates both your hair and skin.",
          options: [
            {
              description: "45 minutes",
              price: 450000,
            },
          ],
        },
      ],
    },
  ],
};
