import React from "react";
import { graphql } from "gatsby";
import GlobalStyle from "../../styles/GlobalStyle";
import {
  ContentBox,
  Center,
  BreadCrumb,
  SectionHeader,
  BgBlock,
  FeatureBlock,
  SubSectionHeader,
  PageTitle,
  TextCenter,
} from "../../components/StandardComponents";

import { MainLayout2 } from "../../components/layouts";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import { getImage } from "../../../lib/image-resolve";
import { Button as CardButton } from "../../components/card";
import { PageImageBanner } from "../../components/PageBanner";
import { encodeObject } from "../../utils";

const FeaturedContentWrapper = styled.div`
  margin: 20px;
`;

const PageImageHeader = styled.div`
  position: relative;
`;

const ImageStyled = styled.img`
  /*Need this to make image fill container to bottom*/
  display: block;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  border: none;
`;

const DetailsSectionDiv = styled.div`
  padding: 20px;
  border: 1px solid #a08036;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OuterDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
`;

const MenuExplorerButtonContainer = styled.div`
  margin-top: 10px;
`;

const IndexPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <MainLayout2
      pageTitle={t("Restaurant & Bar")}
      pageBannerImg={"/assets/restaurant/restaurant-1-1280x300-header-2.jpg"}
      pageCrumbMap={[{ link: "/", name: t("Home") }]}
    >
      <ContentBox>
        <TextCenter>
          {t(
            "Laluna's Moon River restaurant has a excellent view of the Hoai River, we provide a wide variety of local Vietnamese, Asian, and international cuisine options. Weather you're seated inside the restaurant, outside on the front terrace, or next to the pool, we provide a nice relaxing environment, a great view and plenty of dining choices."
          )}
        </TextCenter>
        <OuterDetailsContainer>
          <DetailsSectionDiv>
              <div className={'max-w-md text-center'}>
              {t("Enjoy an exclusive 20% discount on all dining experiences at our Moon River restaurant, exclusively for guests staying with us 2 nights or longer")}
              </div>
          </DetailsSectionDiv>
        </OuterDetailsContainer>
        <OuterDetailsContainer>
          <TextCenter>
            <CardButton
              href={`/contact-us/?encoded=${encodeObject({
                selectedName: "Restaurant Reservation Request",
              })}`}
            >
              Reserve Now
            </CardButton>
          </TextCenter>
        </OuterDetailsContainer>
      </ContentBox>

      <BgBlock>
        <FeatureBlock
          contentLeft={false}
          images={[
            getImage(
              "/assets/restaurant/restaurant-1-350x350.jpg",
              "350square"
            ),
            getImage(
              "/assets/restaurant/restaurant-2-350x350.jpg",
              "350square"
            ),
            getImage(
              "/assets/restaurant/restaurant-3-350x350.jpg",
              "350square"
            ),
            getImage(
              "/assets/restaurant/restaurant-buffet-1-350x350.jpg",
              "350square"
            ),
            getImage(
              "/assets/restaurant/restaurant-buffet-2-350x350.jpg",
              "350square"
            ),
            getImage(
              "/assets/restaurant/restaurant-buffet-3-350x350.jpg",
              "350square"
            ),
            getImage(
              "/assets/restaurant/restaurant-buffet-4-350x350.jpg",
              "350square"
            ),
            getImage(
              "/assets/restaurant/restaurant-outside-1-350x350.jpg",
              "350square"
            ),
            getImage(
              "/assets/restaurant/restaurant-outside-2-350x350.jpg",
              "350square"
            ),
          ]}
        >
          <FeaturedContentWrapper>
            <SubSectionHeader title={t("Moon River Restaurant")}>
              {
                "Come to the Laluna Moon River Restaurant to enjoy our wonderful breakfast buffet, a refreshing beverage, or satify your hunger with our a la carte menu options. We have a wide variety of Vietnamese, Asian, and western food options."
              }
              <OuterDetailsContainer>
                <DetailsSectionDiv>
                  <div>Breakfast Buffet: 6:30 am - 10 am</div>
                  <div>A La Carte: 10 am - 10 pm </div>
                  <div>Room Service: 6 am - 10 pm </div>
                </DetailsSectionDiv>
              </OuterDetailsContainer>
              <MenuExplorerButtonContainer>
                <CardButton href="/restaurant-and-bar/menu">
                  Explore our Menu
                </CardButton>
              </MenuExplorerButtonContainer>
            </SubSectionHeader>
          </FeaturedContentWrapper>
        </FeatureBlock>
      </BgBlock>
      <FeatureBlock
        contentLeft={true}
        images={[
          getImage("/assets/restaurant/pool-bar-1-350x350.jpg", "350square"),
          getImage("/assets/restaurant/pool-bar-2-350x350.jpg", "350square"),
          getImage("/assets/restaurant/pool-bar-3-350x350.jpg", "350square"),
        ]}
      >
        <FeaturedContentWrapper>
          <SubSectionHeader title={t("Pool Bar")}>
            {
              "Come relax by the pool and while you're here, order a drink and have something to eat. We have a wide variety of beverages both with and without alcohol."
            }
            <OuterDetailsContainer>
              <DetailsSectionDiv>
                <div>A La Carte: 10 am - 10 pm</div>
                <div>Drinks {"&"} Cocktails: 10 am - 12 am </div>
                <div>{t("Happy Hour buy one get one free! 4pm to 7pm ")}</div>
              </DetailsSectionDiv>
            </OuterDetailsContainer>
            <MenuExplorerButtonContainer>
              <CardButton href="/restaurant-and-bar/menu">
                Explore our Menu
              </CardButton>
            </MenuExplorerButtonContainer>
          </SubSectionHeader>
        </FeaturedContentWrapper>
      </FeatureBlock>
      <BgBlock>
        <FeatureBlock
          contentLeft={false}
          images={[
            getImage("/assets/restaurant/cooking-1.jpg", "350square"),
            getImage("/assets/restaurant/MPX_6842.jpg", "350square"),
            getImage("/assets/restaurant/MPX_6807.jpg", "350square"),
          ]}
        >
          <FeaturedContentWrapper>
            <SubSectionHeader title={t("Cooking Class")}>
              {"Understanding food is a great way to experience the fascinating cuture of Vietnam." +
                " We invite you to join our cooking class, wich begins with a visit to the local market in the city centre with your own personal chef." +
                " Here, you will learn about and pick out some of the unique ingredients and Vietnamese spices that you will use back ar the Hotel to prepare some of Vietnam's best cuisines."}
              <OuterDetailsContainer>
                <DetailsSectionDiv>
                  <div>Daily from 9:30 am to 1:00 pm</div>
                  <div>Schedule your reservation at reception </div>
                  <div>... or call (+84) 2353 666 678, ext: 118</div>
                  <div>Please make your booking one day in advance</div>
                  <div>750,000 VND per person</div>
                  <MenuExplorerButtonContainer>
                    <CardButton
                      href={`/contact-us/?encoded=${encodeObject({
                        selectedName: "Cooking Class Reservation",
                      })}`}
                    >
                      Book Online
                    </CardButton>
                  </MenuExplorerButtonContainer>
                </DetailsSectionDiv>
              </OuterDetailsContainer>
            </SubSectionHeader>
          </FeaturedContentWrapper>
        </FeatureBlock>
      </BgBlock>
    </MainLayout2>
  );
};

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
