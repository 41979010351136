import React from "react";
import { graphql } from "gatsby";
import GlobalStyle from "../../styles/GlobalStyle";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import {
  ContentBox,
  Center,
  SectionHeader,
  BreadCrumb,
  TextCenter,
} from "../../components/StandardComponents";

import { MainLayout2 } from "../../components/layouts";
import SpaServicesDataProvider from "../../dataProviders/SpaServicesDataProvider";
import { PurchaseMenu } from "../../components/PurchaseMenu/PurchaseMenu";
import { MenuDefArray } from "../../components/PurchaseMenu/Types";
import { PageImageBanner } from "../../components/PageBanner";
import { getImage } from "../../../lib/image-resolve";
import styled from "styled-components";
import CardButton from "../../components/card/CardButton";
import { encodeObject } from "../../utils";

const DetailsSectionDiv = styled.div`
  padding: 20px;
  border: 1px solid #a08036;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OuterDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
`;

const IndexPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <MainLayout2
      pageTitle={t("Spa Services")}
      pageBannerImg={"/assets/spa/spa-banner.jpg"}
      pageCrumbMap={[{ link: "/", name: t("Home") }]}
    >
      <ContentBox>
        <TextCenter>
          {
            "Do you want to relax? Well Laluna Hoi An has you covered, our spa offers only the best and it's all all in one place. If you're looking to spend the day getting pampered or maybe just something quick to help you relax we have a treatment for you."
          }
        </TextCenter>
        <OuterDetailsContainer>
          <DetailsSectionDiv>
            <div> {"Open from 9 am - 10 pm"}</div>
            <div>{"Located on the basement level"}</div>
            <br />
            <div className="max-w-xs text-center">{"Get a 20% discount or enjoy a complimentary one-way transfer to Da Nang airport for any treatment, facial, or foot service. (Offer applies to a minimum of 2 guests with services from 60 minutes)."}</div>
          </DetailsSectionDiv>
        </OuterDetailsContainer>
        <TextCenter>
          <CardButton
            href={`/contact-us/?encoded=${encodeObject({
              selectedName: "Spa Reservation Request",
            })}`}
          >
            Book Online
          </CardButton>
        </TextCenter>
      </ContentBox>

      <SpaServicesDataProvider>
        {(MenuData: MenuDefArray) => {
          return <PurchaseMenu menuData={MenuData}></PurchaseMenu>;
        }}
      </SpaServicesDataProvider>
    </MainLayout2>
  );
};

export default IndexPage;

/*export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;*/
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
